import React, { useImperativeHandle, useRef, useState } from "react";
import { Form, Dropdown, Tooltip, OverlayTrigger, Button } from "react-bootstrap";
import { useValidation } from "../../../hooks/useValidation";
import { usePermissions } from "../../../hooks/usePermissions";
import { BsInfoCircle } from "react-icons/bs";
import { guid } from "../../../../services/form-helpers";
import { set } from "lodash";

export interface CustomerTextareaProps {
  field: any
};
function getWordBoundaries(textarea) {
  const text = textarea.value; // Get the text content of the textarea
  const cursorPosition = textarea.selectionStart; // Get the cursor position

  // Find the start of the word
  let start = cursorPosition;
  while (start > 0 && /\w/.test(text[start - 1])) {
    start--;
  }

  // Find the end of the word
  let end = cursorPosition;
  while (end < text.length && /\w/.test(text[end])) {
    end++;
  }

  return { start, end };
}

export const CustomTextarea = ({ field, setValue, register, required, validation, getValues, data, rowIdentity, isUnique, errors, fieldErrors, formState, watch, editTableProps }) => {
  /*   const [text, setText] = useState(""); */
  const [options, setOptions] = useState([]);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const textAreaRef = useRef(null);
  const dropdownRef = useRef(null);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [customValidation, customValidatorNames] = useValidation({ field, validation, getValues, data, rowIdentity, isUnique, errors });
  const { touchedFields } = formState?.formState;
  const isDirty = touchedFields && touchedFields[field.name]//;.find(x => x.name == field.name);
  const [localWrite] = usePermissions(field.permissions);
  const hide = field.hideIf ? field.hideIf({ editTableProps, rowIdentity, watch }) : false;


  const loadOptions = async () => {
    const options = await field.options({ getValues });
    setOptions(options);
  }
  React.useEffect(() => {
    loadOptions();
  }, [])

  const renderTooltip = (props) => {
    return (
      <Tooltip id="button-tooltip" {...props} >
        {field.tooltip}
      </Tooltip>
    )
  }

  const handleKeyDown = (e) => {
    if (!showDropdown) return;

    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setHighlightedIndex((prevIndex) => prevIndex < filteredSuggestions.length - 1 ? prevIndex + 1 : 0)
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setHighlightedIndex((prevIndex) => prevIndex > 0 ? prevIndex - 1 : filteredSuggestions.length - 1)
    } else if (e.key === 'Enter') {
      e.preventDefault();
      handleSelect(filteredSuggestions[highlightedIndex])
    } else if (e.key === 'Escape') {
      e.preventDefault();
      setShowDropdown(false);
    }
  }
  const getWord = (s, pos) => {
    const n = s.substring(pos).match(/^[a-zA-Z0-9-_]+/)
    const p = s.substring(0, pos).match(/[a-zA-Z0-9-_]+$/)
    // if you really only want the word if you click at start or between
    // but not at end instead use if (!n) return
    if (!p && !n) return ''
    return (p || '') + (n || '')
  }
  const handleInputChange = (e) => {
    console.log('input change event!')
    const inputValue = getWord(e.target.value, e.target.selectionStart);

    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const rect = range.getBoundingClientRect();

    /*  const coords = getCaretCoordinates(e.target, e.target?.selectionStart); */
    setCursorPosition({ x: rect.left, y: rect.top });


    setCursorPosition(e.target.selectionStart);
    setValue(field.name, e.target.value);
    /*   setRefreshKey(guid()); */

    // Filter the suggestions based on the input value
    const filtered = options.filter((item) =>
      item.toLowerCase().includes(inputValue.toLowerCase())
    );

    setFilteredSuggestions(filtered);
    setShowDropdown(filtered.length > 0); // Show dropdown only if there are matches
  };

  const handleSelect = (item) => {
    /*   const inputValue = getWord(e.target.value, e.target.selectionStart); */
    const boundary = getWordBoundaries(textAreaRef.current);
    const inputValue = textAreaRef.current.value;
    const newStr = inputValue.substring(0, boundary.start) + item + inputValue.substring(boundary.end);

    setValue(field.name, newStr); // Update the textarea value with the selected item
    /*    setRefreshKey(guid()); */
    setShowDropdown(false); // Hide dropdown
    /*     if (onSelect) onSelect(item); // Notify the parent component */
  };

  const handleBlur = (e) => {
    // Check if the blur event happened outside the dropdown and textarea
/*     if (!dropdownRef.current?.contains(e.relatedTarget)) {
      setShowDropdown(false);
    } */
  };

  const { ref, ...rest } = register(field.name, {
    required: required && 'Required',
    min: typeof field.min != 'undefined' ? { value: field.min, message: 'must be greater than 0' } : undefined,
    validate: { ...customValidation },
    maxLength: field.maxLength,
    minLength: field.minLength
  })
  useImperativeHandle(ref, () => textAreaRef.current);

  return (
    /*    <div style={{ position: "relative", width: "100%" }} onBlur={handleBlur}> */
    <Form.Group className={editTableProps ? undefined : "mb-1"} style={{ position: "relative", width: "100%" }} onBlur={handleBlur}>
      {field.label && !field.tooltip && <Form.Label>{field.label}</Form.Label>}
      {field.label && field.tooltip && (
        <><Form.Label>{field.label}</Form.Label>
          <OverlayTrigger
            placement="top"
            delay={{ show: 0, hide: 0 }}
            overlay={renderTooltip}
          >
            <Button variant="link" style={{ backgroundColor: 'transparent', color: 'unset', padding: 0, paddingLeft: 6 }}><BsInfoCircle style={{ marginTop: -6 }} size={15} /></Button>
          </OverlayTrigger></>
      )
      }
      <Form.Control
        size="sm"
        autoComplete="do-not-autofill"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        as="textarea"
        rows={1}
        /*     value={text} */

        style={{ resize: "none", minWidth: field.minWidth ? field.minWidth : undefined, background: isDirty ? '#e6edff' : undefined }}
        onKeyDown={handleKeyDown}

        className={field.className}

        isInvalid={fieldErrors}

        title={field.title}
        disabled={!localWrite || (field.disabled ? field.disabled({ watch, editTableProps }) : undefined)}
        readOnly={field.disabled ? field.disabled({ watch, editTableProps }) : undefined}
        onChange={handleInputChange}
        ref={textAreaRef}
      />
      {showDropdown && (
        <Dropdown.Menu
          role="menu"
          ref={dropdownRef}
          style={{
            position: "absolute",
            width: "100%",
            zIndex: 1111,
            top: `${cursorPosition.y + 20}px`, // Adjust for height
            left: `${cursorPosition.x}px`,
          }}
          show
        >
          {filteredSuggestions.map((item, index) => (
            <Dropdown.Item active={index === highlightedIndex} key={index} onClick={() => handleSelect(item)}>
              {item}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      )}
      {
        fieldErrors && fieldErrors.type === "unique" && <Form.Control.Feedback type="invalid">
          must be unique
        </Form.Control.Feedback>
      }
      {
        fieldErrors && fieldErrors.type === "min" && <Form.Control.Feedback type="invalid">
          {'must be greater than ' + field.min}
        </Form.Control.Feedback>
      }
      {
        fieldErrors && fieldErrors.type === "required" && <Form.Control.Feedback type="invalid">
          {'this field is required'}
        </Form.Control.Feedback>
      }
      {
        fieldErrors && (customValidatorNames as string[])?.includes(fieldErrors.type) && <Form.Control.Feedback type="invalid">
          {validation[fieldErrors.type].message}
        </Form.Control.Feedback>
      }
      {
        fieldErrors && fieldErrors.type === "maxLength" && <Form.Control.Feedback type="invalid">
          {'max length is ' + field.maxLength}
        </Form.Control.Feedback>
      }
      {
        fieldErrors && fieldErrors.type === "minLength" && <Form.Control.Feedback type="invalid">
          {'min length is ' + field.minLength}
        </Form.Control.Feedback>
      }
    </Form.Group>
  );
};


