import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '@arcgis/core/assets/esri/themes/light/main.css';

/* import store from './store/store'; */
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { configureStore } from '@reduxjs/toolkit';
import axios from 'axios';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
// MSAL Auth imports
import { msalConfig, protectedResources } from './authConfig';
import ErrorBoundary from './components/templates/Errors/error-boundary';
import ConfirmContextProvider from './components/UI/organisms/ConfirmDialog/confirm-context-provider';
import ConfirmDialog from './components/UI/organisms/ConfirmDialog/confirm-dialog';
import reportWebVitals from './reportWebVitals';
// Redux imports
/* import store from './store/store'; */
import { reduxReducer } from './store/store';

export const store = configureStore({
  reducer: reduxReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

TimeAgo.addDefaultLocale(en);

const msalInstance = new PublicClientApplication(msalConfig);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status >= 400
      && error.response.status < 500
      && error.response.status !== 404
    ) {
      if (originalRequest.requestRetry === undefined || originalRequest.requestRetry < 2) {
        console.log('refreshing token');
        // eslint-disable-next-line max-len
        originalRequest.requestRetry = originalRequest.requestRetry === undefined ? 1 : originalRequest.requestRetry + 1;
        const accounts = msalInstance.getAllAccounts();
        const account = accounts[0];
        const tokenRequest = {
          account,
          scopes: protectedResources.appServiceApi.scopes,
        };

        const resp = await msalInstance.acquireTokenSilent(tokenRequest).catch(async (e) => {
          // Catch interaction_required errors and call interactive method to resolve
          await msalInstance.acquireTokenRedirect(tokenRequest);
        });

        axios.defaults.headers.common.Authorization = `Bearer ${resp.accessToken}`;
        originalRequest.headers.Authorization = `Bearer ${resp.accessToken}`;
        return axios(originalRequest);
      }

      return Promise.reject(error);
    }
    return Promise.reject(error);
  },
);
/* const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(<App />); */
/* eslint-disable */

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
    {/*     <BrowserRouter> */}
          <ErrorBoundary>
            <ConfirmContextProvider>
              <App />
              <ConfirmDialog />
            </ConfirmContextProvider>
          </ErrorBoundary>
{/*         </BrowserRouter> */}
      </Provider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
