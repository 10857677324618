import React from "react"
import { Badge } from "react-bootstrap";
import { WorldMapFilter } from "./world-map";
import { filter } from "lodash";

export const ColorLegend = (props) => {
  const { filters } = props

  return (
    <div>
      {
        filters.map(f => f.icon ?  <img title={f.display} style={{...f.iconStyle}} src={f.icon} /> : <Badge bg={f.legendColor}>{f.display}</Badge>)
      }
    </div>
  );
}