import { FormItem, FormView } from '../components/UI/view/form-view';
import { FormItemType } from '../components/UI/view/component-map';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import _ from "lodash";
// @ts-ignore
const moment = extendMoment(Moment);

export const getFormFieldCollection = (formItems: any[], fieldName: string) => {
  const defaults = {};
  const flat = flatten(formItems);

  flat.forEach(field => {
    if (field.name && typeof field[fieldName] != 'undefined')
      defaults[field.name] = field[fieldName];
  });

  const navProperties = [...new Set(Object.keys(defaults)
    .filter(key => key.includes('.'))
    .map(key => key.substring(0, key.indexOf('.'))))];

  navProperties.forEach(navProp => {
    defaults[navProp] = prefixValues(navProp, defaults);
  });


  return defaults;
}

const prefixValues = (prefix, obj) => {
  const keys = Object.keys(obj).filter(k => k.startsWith(prefix));
  const newObj = {};
  keys.forEach(key => {
    newObj[key.substring(key.indexOf('.') + 1)] = obj[key]
  });
  return newObj;
}

export const flatten = (formItems: any[]) => {
  const result = [] as any[];
  formItems.forEach(item => {
    // const { items } = item;
    result.push(item);
    if (item.items)
      result.push(...flatten(item.items));
  });
  return result;
}

export const getRowError = (errors: any, fieldName: any, rowIdentity?: any) => {
  const err = _.get(errors, fieldName);
  return err ? true : false;
}

export const getError = (errors: any, fieldName: any, rowIdentity?: any) => {
  const err = _.get(errors, fieldName);
  return err;
}
export const mergeForReset = (obj1: any, obj2: any) => {
  let answer = {} as any;
  for (let key in obj1) {
    if (answer[key] === undefined || answer[key] === null)
      answer[key] = obj1[key];
  }
  for (let key in obj2) {
    if (obj2[key] !== undefined && (obj2[key] !== null || answer[key] === undefined))
      answer[key] = obj2[key];
  }
  return answer
}
export const merge = (obj1: any, obj2: any) => {
  let answer = {} as any;
  for (let key in obj1) {
    if (answer[key] === undefined || answer[key] === null)
      answer[key] = obj1[key];
  }
  for (let key in obj2) {
    if (obj2[key] !== undefined && obj2[key] !== null)
      answer[key] = obj2[key];
  }
  return answer
}

export const uniqueList = (data, pk) => {
  const uniqueL = data.reduce((accumulator, current) => {
    if (!accumulator.find((item) => item[pk] === current[pk])) {
      accumulator.push(current);
    }
    return accumulator;
  }, []);
  return uniqueL;
}

export const validateIntervalOverlaps = (INTERVALS, INTERVAL_START, INTERVAL__END) => {
  if (INTERVAL_START && INTERVAL__END) {
    const timeInterval = moment.range(moment(INTERVAL_START), moment(INTERVAL__END))

    const overlappingInterval = INTERVALS.find(intervalItem => {
      const interval = moment.range(moment(intervalItem.START), moment(intervalItem.END))
      return timeInterval.overlaps(interval)
    })

    return overlappingInterval
  }
}

export const preceeds = (date1, date2) => {
  const d1 = moment(date1)
  const d2 = moment(date2)
  return d1 < d2;
}
export const addYears = (date, years) => {
  const dt = moment(date);
  dt.add(years, 'y');
  return dt.toDate();
}
export const getCurrentYear = () => {
  return new Date().getFullYear();
}
export const setYear = (date, year) => {
  const dt = moment(date);
  dt.set('year', year);
  return dt.toDate();
}
export const addDays = (date, days) => {
  const dt = moment(date);
  dt.add(days, 'd');
  return dt.toDate();
}
export const today = () => {
  let now = new Date();
  now.setHours(0, 0, 0, 0)
  return now;
}
export const pastRange = (startDate, endDate) => {
  let endDateConverted = new Date(new Date(endDate).setHours(0, 0, 0, 0));
  const isExpired = endDateConverted <= today();
  return isExpired;
}
export const currentRange = (startDate, endDate) => {
  let startDateConverted = new Date(new Date(startDate).setHours(0, 0, 0, 0));
  let endDateConverted = new Date(new Date(endDate).setHours(0, 0, 0, 0));
  const isExpired = endDateConverted <= today();
  const inProgress = startDateConverted <= today();
  return !isExpired && inProgress;
}
export const futureRange = (startDate, endDate) => {
  let startDateConverted = new Date(new Date(startDate).setHours(0, 0, 0, 0));
  let endDateConverted = new Date(new Date(endDate).setHours(0, 0, 0, 0));
  const isExpired = endDateConverted <= today();
  const inProgress = startDateConverted <= today();
  return !isExpired && !inProgress;
}

export const transformModelForEditTablePartitions = (entity: any, form: FormView) => {
  //add nav properties for each of the 
  //get all edit tables
  // const tables = getFormFieldCollection(form.items, 'type').filter(formField => formField.type == FormItemType.editTable);

  return entity;
}

function makeid(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export const guid = () => {
  return makeid(5);
}
