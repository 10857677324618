import { create } from "lodash";
import { FormView } from "../../UI/view/form-view";
import { createConnectedView } from "../../UI/view/view";
import { alarmKeyLocationsDataSource } from "./alarm-datasource";
import { createConnectedListView, ListView, ListViewColumnType } from "../../UI/view/list-view";
import { FormItemType } from "../../UI/view/component-map";


export const updateKeyLocationsView = {
  editMode: true,
  items: [
    {
      name: 'keyLocationType',
      label: 'Key Location Type',
      type: FormItemType.select,
      options: [
        { id: null, text: '' },
        { id: 'Plant', text: 'Plant' },
        { id: 'Customer', text: 'Customer' },
        { id: 'Port', text: 'Port' }
      ],
      required: true
    },
    {
      name: 'latitude',
      label: 'Latitude'
    },
    {
      name: 'longitude',
      label: 'Longitude'
    }
  ]

};

export const keyLocationsListView = {
  update: updateKeyLocationsView,
  create: updateKeyLocationsView,
  columns: [
    {
      type: ListViewColumnType.editdelete,
      accessor: 'editcolumn'
    },
    {
      accessor: 'keyLocationType',
      Header: 'Key Location Type',
      defaultSort: true
    },
    {
      accessor: 'latitude',
      Header: 'Latitude'
    },
    {
      accessor: 'longitude',
      Header: 'Longitude'
    }
  ]
} as ListView;

export const KeyLocationsView = createConnectedListView(keyLocationsListView, alarmKeyLocationsDataSource);