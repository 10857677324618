import { Typeahead } from "react-bootstrap-typeahead";
import { FormItemType } from "../../UI/view/component-map";
import { FormView } from "../../UI/view/form-view";
import { createConnectedView } from "../../UI/view/view";
import { alarmDataSource } from "./alarm-datasource";

export const scrapView = {
  editMode: true,
  items: [
    {
      type: FormItemType.customTextarea,
      options: ['apples', 'banana'],
      name: 'customTextarea',
      label: 'a custom'
    },
    {


      name: 'asd',
    }
/*     {
      label: 'Container Ended',
      name: 'containerIdended',
      freeform: true,
      type: FormItemType.expressionBox,
      options: async () => {
        let list = [{ fieldName: 'FieldName1', label: 'Field Name 1' }, { fieldName: 'FieldName2', label: 'Field Name 2' }]
        return [...list.map(t => ({ id: t.fieldName, label: `${t.label}` }))]
      },
    }, */
  ]
} as FormView;


export const ScrapView = createConnectedView({form: scrapView, dataSource: alarmDataSource})