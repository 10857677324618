import { FormItemType } from "../../UI/view/component-map";
import { FormView } from "../../UI/view/form-view";
import { createConnectedListView2, FilterType, ListView, ListViewColumn, ListViewColumnType } from "../../UI/view/list-view";
import { createConnectedView } from "../../UI/view/view";
import { alarmMapDataSource } from "./alarm-datasource";

/* export const craneSVG = `
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="#000000"  version="1.1" id="Capa_1" viewBox="0 0 395 395" xml:space="preserve">
<g>
	<path d="M297.567,223.101c-0.799-0.025-1.552,0.435-1.887,1.169l-0.392,0.861c-2.256,4.956-4.387,9.638-7.411,14.001   c-8.509,12.273-21.229,21.763-38.886,29.011c-10.839,4.449-23.025,7.309-36.357,8.557V175.038   c19.157-6.36,33.018-24.437,33.018-45.701c0-26.551-21.601-48.152-48.151-48.152s-48.151,21.602-48.151,48.152   c0,21.265,13.861,39.341,33.018,45.701v101.589c-2.461-0.227-4.888-0.498-7.322-0.879c-18.874-2.95-34.992-8.808-47.903-17.414   c-7.024-4.682-12.965-10.086-17.658-16.063c-4.46-5.68-7.464-12.003-10.159-17.992c-0.331-0.736-1.082-1.24-1.876-1.179   c-0.806,0.02-1.52,0.524-1.812,1.275c-4.74,12.217-3.162,24.307-1.003,32.298c9.893,36.624,46.588,56.821,75.005,62.989   c9.166,1.99,18.585,2.999,27.993,2.998c22.589,0,44.135-5.732,62.308-16.576c15.959-9.523,28.24-22.217,35.517-36.708   c7.574-15.085,9.001-31.481,3.916-44.983C299.087,223.639,298.375,223.128,297.567,223.101z M197.5,108.7   c11.379,0,20.637,9.258,20.637,20.637s-9.258,20.636-20.637,20.636c-11.379,0-20.637-9.257-20.637-20.636   S186.121,108.7,197.5,108.7z"/>
	<path d="M313.002,0H82C36.785,0,0,36.784,0,81.998v230.993C0,358.211,36.785,395,82,395h231.002   C358.216,395,395,358.211,395,312.991V81.998C395,36.784,358.216,0,313.002,0z M380,312.991C380,349.94,349.945,380,313.002,380H82   c-36.944,0-67-30.06-67-67.009V81.998C15,45.056,45.056,15,82,15h231.002C349.945,15,380,45.056,380,81.998V312.991z"/>
</g>
</svg>
`;

export const plantSVG = `
<svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" class="bi bi-building" viewBox="0 0 16 16">
  <path d="M4 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"/>
  <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1zm11 0H3v14h3v-2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V15h3z"/>
</svg>
`;

export const peopleSVG = `
<svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
  <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
</svg>
`; */
/* export const peopleSVG = `
M297.567,223.101c-0.799-0.025-1.552,0.435-1.887,1.169l-0.392,0.861c-2.256,4.956-4.387,9.638-7.411,14.001   c-8.509,12.273-21.229,21.763-38.886,29.011c-10.839,4.449-23.025,7.309-36.357,8.557V175.038   c19.157-6.36,33.018-24.437,33.018-45.701c0-26.551-21.601-48.152-48.151-48.152s-48.151,21.602-48.151,48.152   c0,21.265,13.861,39.341,33.018,45.701v101.589c-2.461-0.227-4.888-0.498-7.322-0.879c-18.874-2.95-34.992-8.808-47.903-17.414   c-7.024-4.682-12.965-10.086-17.658-16.063c-4.46-5.68-7.464-12.003-10.159-17.992c-0.331-0.736-1.082-1.24-1.876-1.179   c-0.806,0.02-1.52,0.524-1.812,1.275c-4.74,12.217-3.162,24.307-1.003,32.298c9.893,36.624,46.588,56.821,75.005,62.989   c9.166,1.99,18.585,2.999,27.993,2.998c22.589,0,44.135-5.732,62.308-16.576c15.959-9.523,28.24-22.217,35.517-36.708   c7.574-15.085,9.001-31.481,3.916-44.983C299.087,223.639,298.375,223.128,297.567,223.101z M197.5,108.7   c11.379,0,20.637,9.258,20.637,20.637s-9.258,20.636-20.637,20.636c-11.379,0-20.637-9.257-20.637-20.636   S186.121,108.7,197.5,108.7z M313.002,0H82C36.785,0,0,36.784,0,81.998v230.993C0,358.211,36.785,395,82,395h231.002   C358.216,395,395,358.211,395,312.991V81.998C395,36.784,358.216,0,313.002,0z M380,312.991C380,349.94,349.945,380,313.002,380H82   c-36.944,0-67-30.06-67-67.009V81.998C15,45.056,45.056,15,82,15h231.002C349.945,15,380,45.056,380,81.998V312.991z
`;

export const craneSVG = `
M4 2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zM4 5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zM7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zM4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1zm11 0H3v14h3v-2.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V15h3z
`;

export const plantSVG = `
M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4
`; */
export const peopleSVG = `
/customer.png
`;

export const craneSVG = `
/plant.png
`;

export const plantSVG = `
/refinery.png
`;

export const geofenceSVG = `
/geofence.png
`;

export const worldMapList: ListView = {
  filters: [
    {
      type: FilterType.multiselect,
      label: 'Asset Type',
      fieldName: 'assetType',
      options: (state) => ['Liquid', 'Gas', 'Chassis', 'Plant', 'Customer', 'Port'],
      filterValue: { 'Liquid': true, 'Gas': true, 'Chassis': true, 'Plant': true, 'Customer': true, 'Port': true }
    }
  ],
  columns: [
    {
      Header: 'Asset Id',
      accessor: 'containerId',
      defaultSort: true,
      type: ListViewColumnType.link,
      linkPath: ({ value, row }) => `/nope`
    },
    {
      Header: 'Asset Type',
      accessor: 'assetType',
    },
    {
      Header: 'Latitude',
      accessor: 'latitude',
    },
    {
      Header: 'Longitude',
      accessor: 'longitude',
    },
  ] as ListViewColumn[]

};
export const [WorlMapListComponent, WorlMapListComponentStore] = createConnectedListView2(worldMapList, alarmMapDataSource);

export const alarmWorldMap = {
  type: FormItemType.worldmap,
  name: 'geofencemap',
  componentArgs: {
    listComponent: WorlMapListComponent,
    detailsEndpoint: null,
    detailsLink: null,
    filterField: 'assetType',
    filters: [
      {
        type: 'Liquid', color: [0, 85, 145], legendColor: 'primary', display: 'Liquid',
      },
      {
        type: 'Gas', color: '#28a745', legendColor: 'success', display: 'Gas',
      },
      {
        type: 'Chassis', color: 'red', legendColor: 'danger', display: 'Chassis',
      },
      {
        type: 'Plant', display: 'Plant', icon: plantSVG, iconStyle: { width: '20px', height: '20px', display: 'inline-block', paddingRight: 2, paddingLeft: 2 }
      },
      {
        type: 'Customer', display: 'Customer', icon: peopleSVG, iconStyle: { width: '20px', height: '20px', display: 'inline-block', paddingRight: 2 }
      },
      {
        type: 'Port', display: 'Port', icon: craneSVG, iconStyle: { width: '20px', height: '20px', display: 'inline-block', paddingRight: 2 }
      },
      {
        type: 'Geofence', display: 'Geofence', icon: geofenceSVG, iconStyle: { width: '20px', height: '20px', display: 'inline-block', paddingRight: 2 }
      },
    ],
    titleRenderer: (datum) => {
      return `${datum.latitude} ${datum.longitude} [${datum.assetType}]`;
    },

  }
}

/*  export const AlarmWorldMap = createConnectedView({ form: alarmWorldMap, dataSource: alarmMapDataSource }); */