import { liquidCustomerStore } from "../../../store/store";
import { FormView } from "../../UI/view/form-view";
import { FormItemType } from "../../UI/view/component-map";
import { createView } from "../../UI/view/view";

import { customerPermissions } from "./customer-data-source";
import { getCOCReports, getDeliveryTypes } from "../../../services/requests";

export const customerLiquidCreate = {
  permissions: customerPermissions,
  items: [
    {
      type: FormItemType.fieldset,
      label: 'Details',
      items: [
        {
          type: FormItemType.row,
          items: [
            { label: 'Customer ID', name: 'customerId', type: FormItemType.text, number: true, required: true, unique: true },
            { label: 'Customer Name', name: 'customerName', type: FormItemType.text, number: false, required: true, defaultValue: undefined, unique: true },
          ]
        },
        {
          type: FormItemType.row,
          items: [
            { label: 'Shipping Address', name: 'shippingAddress', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
            { label: 'Shipping Address 2', name: 'shippingAddress2', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
          ]
        },
        {
          type: FormItemType.row,
          items: [
            { label: 'City', name: 'city', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
            { label: 'State', name: 'state', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
          ]
        },
        {
          type: FormItemType.row,
          items: [
            { label: 'Zip', name: 'zip', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
            { label: 'PO Number', name: 'poNumber', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
          ]
        },
        {
          type: FormItemType.row,
          items: [
            { label: 'BDTCPO', name: 'bdtcpo', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
            { 
              label: 'Specs', 
              name: 'specs', 
              type: FormItemType.select, 
              options: [{ text: "", id: null }, { text: "Liquid", id: "Liquid" }, { text: "High Purity", id: "High Purity" }, { text: "Special", id: "Special" }],
              number: false, 
              required: false, 
              defaultValue: undefined, 
            },
          ]
        },
        {
          type: FormItemType.row,
          items: [
            { label: 'Spec Special', name: 'specSpecial', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
            { label: 'AFS Required', name: 'afsrequired', type: FormItemType.yn, number: false, required: true, defaultValue: 'N', },
          ]
        },
        { label: 'Delivery Request', name: 'deliveryRequest', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
        { label: 'Other Requests', name: 'otherRequests', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
        {
          type: FormItemType.row,
          items: [
            { label: 'Maximum Shipping Pressure', name: 'maximumShippingPressure', type: FormItemType.text, number: true, required: true, defaultValue: 0, },
            { label: 'Maximum Shipping Weight', name: 'maximumShippingWeight', type: FormItemType.text, number: true, required: true, defaultValue: 0, },
          ]
        },
        {
          label: 'Status',
          name: 'status',
          type: FormItemType.select,
          required: true,
          options: [{ text: "", id: null }, { text: "Active", id: "Active" }, { text: "In-Active", id: "In-Active" }]
        },
        {
          name: 'cocid',
          label: 'COC',
          type: FormItemType.select,
          defaultValue: 0,
          required: true,
          options: async () => {
            const res = await getCOCReports();
            return [{ id: '', text: '-- Select --' }, ...res.map(rec => ({ id: rec.cocid, text: rec.cocName }))];
          }
        },
        { 
          label: 'COC Text', 
          name: 'cocdocumentText', 
          type: FormItemType.textarea, 
          number: false, 
          required: false, 
          defaultValue: undefined, 
          tooltip: `NOTE: If needed, please use the following input place holders (%abc%) in the COC text:
          Date = %DATE%, Container = %CONTAINER%, Order Number = %ORDERNUMBER%,
          Temperature = %TEMPERATURE%, Pressure = %PRESSURE%, Purity = %PURITY%,
          Level = %LEVEL%`
        },
        
        { label: 'Driver Instruction', name: 'driverInstruction', type: FormItemType.textarea, number: false, required: false, defaultValue: undefined, },
        { label: 'Driver Routing', name: 'driverRouting', type: FormItemType.textarea, number: false, required: false, defaultValue: undefined, },
        {
          type: FormItemType.row,
          items: [
            { 
              label: 'Region', 
              name: 'region', 
              type: FormItemType.select, 
              number: false, 
              required: false, 
              defaultValue: undefined, 
              options: [
                {id: null, text: '-- Select --'},
                {id: 'Internal', text: 'Internal'},
                {id: 'International Directs', text: 'International Directs'},
                {id: 'PDI', text: 'PDI'},
                {id: 'Praxair Asia', text: 'Praxair Asia'},
                {id: 'Praxair Electronics', text: 'Praxair Electronics'},
                {id: 'Praxair Europe', text: 'Praxair Europe'},
                {id: 'Praxair Mexico', text: 'Praxair Mexico'},
                {id: 'Praxair Puerto Rico', text: 'Praxair Puerto Rico'},
                {id: 'Praxair South America', text: 'Praxair South America'},
                {id: 'Repair Shop', text: 'Repair Shop'},
                {id: 'US Directs', text: 'US Directs'},
                {id: 'US Gov', text: 'US Gov'},
              ]
            },
          ]
        },
        {
          type: FormItemType.row,
          items: [
            { label: 'Ave Net Vol', name: 'aveNetVol', type: FormItemType.text, number: true, required: false, defaultValue: undefined, },
            { label: 'Miles', name: 'miles', type: FormItemType.text, number: true, required: false, defaultValue: undefined, },
          ]
        },
        {
          type: FormItemType.row,
          items: [
            { label: 'Turn Days', name: 'turnDays', type: FormItemType.text, number: true, required: true, defaultValue: 0, },
            { label: 'Container Style', name: 'requiredContainerStyle', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
          ]
        },
        {
          type: FormItemType.row,
          items: [
            { label: 'Container MAWP (PSIG)', name: 'requiredContainerMawp', type: FormItemType.text, number: true, required: true, defaultValue: 0, },
            { label: 'Helium Shield Required', name: 'heliumShieldRequired', type: FormItemType.yn, number: false, required: false, defaultValue: undefined, },
          ]
        },
        {
          type: FormItemType.row,
          items: [
            { label: 'PBC Required', name: 'pbcrequired', type: FormItemType.yn, number: false, required: false, defaultValue: undefined, },
            { 
              label: 'Recommended Fill Plant', 
              name: 'recommendedFillPlant', 
              type: FormItemType.select, 
              options: [
                {id: null, text: '-- Select --'},
                {id: 'Algeria', text: 'Algeria'},
                {id: 'Amur', text: 'Amur'},
                {id: 'Bushton', text: 'Bushton'},
                {id: 'Darwin', text: 'Darwin'},
                {id: 'Exxon', text: 'Exxon'},
                {id: 'Qatar', text: 'Qatar'},
                {id: 'Tumbleweed', text: 'Tumbleweed'},
                {id: 'Ulysses', text: 'Ulysses'},
              ],
              number: false, 
              required: false, 
              defaultValue: undefined, 
            },
          ]
        },
        {
          type: FormItemType.row,
          items: [
            { label: 'One Way Travel Time', name: 'oneWayTravelTime', type: FormItemType.text, number: true, required: true, defaultValue: 0, },
            { label: 'Hourly Maintenance Rate', name: 'hourlyMaintenanceRate', type: FormItemType.text, number: true, required: true, defaultValue: 0, },
          ]
        },
        {
          type: FormItemType.row,
          items: [
            { label: 'Containers Maintained By Praxair', name: 'containersMaintainedByPraxair', type: FormItemType.checkboxField, number: false, required: true, defaultValue: false, },
            { label: 'Container Return Email', name: 'containerReturnEmail', type: FormItemType.yesno, number: false, required: false, defaultValue: undefined, },
          ]
        },
        {
          type: FormItemType.row,
          items: [
            { label: 'Email Address', name: 'emailAddress', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
            { label: 'Container Ship Email', name: 'containerShipEmail', type: FormItemType.yesno, number: false, required: false, defaultValue: undefined, },
          ]
        },
        {
          type: FormItemType.row,
          items: [
            { label: 'Bushton Miles', name: 'bushtonmiles', type: FormItemType.text, number: true, required: false, defaultValue: undefined, },
            { label: 'Dukemiles', name: 'dukemiles', type: FormItemType.text, number: true, required: false, defaultValue: undefined, },
          ]
        },
        {
          type: FormItemType.row,
          items: [
            { label: 'Ulysses miles', name: 'ulyssesmiles', type: FormItemType.text, number: true, required: false, defaultValue: undefined, },
            { label: 'Exxon Miles', name: 'exxonmiles', type: FormItemType.text, number: true, required: false, defaultValue: undefined, },
          ]
        },
        {
          type: FormItemType.row,
          items: [
            { label: 'Is Customs Invoice Required', name: 'isCustomsInvoiceRequired', type: FormItemType.checkboxField, number: false, required: true, defaultValue: false, },
            { label: 'Freight Forwarder Email', name: 'freightForwarderEmail', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
          ]
        },
        {
          type: FormItemType.row,
          items: [
            { label: 'Exclude from Locations', name: 'isNonLocationCustomer', type: FormItemType.checkboxField, number: false, required: true, defaultValue: false, },
            { label: 'Shield Activation Required', name: 'shieldActivationRequired', type: FormItemType.checkboxField, number: false, required: true, defaultValue: false, },
          ]
        },
        {
          type: FormItemType.row,
          items: [
            { 
              label: 'Default Delivery Type', 
              name: 'defaultDeliveryTypeId', 
              type: FormItemType.select, 
              options: async () => {
                const dt = await getDeliveryTypes(1);
                return [{ id: '', text: '-- Select --' }, ...dt.map(rec => ({ id: rec.id, text: rec.name }))];
              },
              number: true, 
              required: false, 
              defaultValue: undefined, 
            },
            { label: 'Bushton Transit Time', name: 'bushtonTransitTime', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
          ]
        },
        {
          type: FormItemType.row,
          items: [
            { label: 'Ulysses Transit Time', name: 'ulyssesTransitTime', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
            { label: 'Duke Transit Time', name: 'dukeTransitTime', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
          ]
        },
        {
          type: FormItemType.row,
          items: [
            { label: 'Exxon Transit Time', name: 'exxonTransitTime', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
            { label: 'APCI Transit Days', name: 'apcitransitTime', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
          ]
        },
        {
          type: FormItemType.row,
          items: [
            { label: 'Qatar Transit Time', name: 'qatarTransitTime', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
            { label: 'Algeria Transit Time', name: 'algeriaTransitTime', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
          ]
        },
        {
          type: FormItemType.row,
          items: [
            { label: 'Is Treat As Domestic For Planning', name: 'isTreatAsDomesticForPlanning', type: FormItemType.checkboxField, number: false, required: true, defaultValue: false, },
            { label: 'Country', name: 'country', type: FormItemType.text, number: false, required: false, defaultValue: undefined, },
          ]
        },
        {
          type: FormItemType.row,
          items: [
            { label: 'USA Cutoff to Arrival Days', name: 'usacutoffToArrivalDays', type: FormItemType.text, number: true, required: false, defaultValue: undefined, },
            { label: 'QTR Cutoff to Arrival Days', name: 'qtrcutoffToArrivalDays', type: FormItemType.text, number: true, required: false, defaultValue: undefined, },
          ]
        },
        { label: 'Algeria Cutoff to Arrival Days', name: 'algcutoffToArrivalDays', type: FormItemType.text, number: true, required: false, defaultValue: undefined, },
        {
          type: FormItemType.row,
          items: [
            { 
              label: 'Legacy Organization', 
              name: 'legacyOrganization', 
              type: FormItemType.select, 
              options: [
                {id: 'Praxair', text: 'Praxair'},
                {id: 'Linde APAC', text: 'Linde APAC'},
                {id: 'Linde EMEA', text: 'Linde EMEA'},
              ],
              number: false, 
              required: true, 
              defaultValue: 'Praxair', 
            },
            { label: 'Include Fill Sheet With Invoice', name: 'includeFillSheetWithInvoice', type: FormItemType.checkboxField, number: false, required: true, defaultValue: false, },
          ]
        },
        {
          type: FormItemType.row,
          items: [
            { label: 'Is Split Inland Freight Charge', name: 'isSplitTransportCharge', type: FormItemType.checkboxField, number: false, required: true, defaultValue: false, },
            { label: 'No Invoice COA', name: 'isNoInvoiceCoa', type: FormItemType.checkboxField, number: false, required: true, defaultValue: false, },
          ]
        },
        { label: 'Logistics Only Notes', name: 'logistIcsOnlyNotes', type: FormItemType.textarea, number: false, required: false, defaultValue: undefined, },
      ]
    }
  ]

} as FormView;

export const CustomerLiquidCreate = createView({ form: customerLiquidCreate as any, repository: liquidCustomerStore as any });