import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { FormModal } from "./FormModal";
import { useNavigate } from "react-router-dom";
import { usePermissions } from "../../../hooks/usePermissions";
import useConfirm from '../../../UI/organisms/ConfirmDialog/use-confirm';


export const FormButton = ({ confirm, entity, modalContext, handleSubmit, onSubmit, writeAccess, isAdmin, editTableProps, setValue, watch, field, control, fieldErrors, validation, getValues, data, rowIdentity, isUnique, required, register, errors, formState }) => {
  const { modal } = field;
  const { form, submitAction, repository, size, title } = modal || {};
  const [isAwaiting, setIsAwaiting] = useState(false);
  const [dynamicIcon, setDynamicIcon] = useState(null);
/*   const [dynamicIconStyle, setDynamicIconStyle] = useState(null); */

  useEffect(() => {
    if(dynamicIcon){
      const timer = setTimeout(() => {
        setDynamicIcon(null);
      }, 3000); // Hide the element after 5 seconds
  
      return () => clearTimeout(timer); // Clear the timer on unmount to prevent memory leaks
    }

  }, [dynamicIcon]);

  const redirect = useNavigate();
  const hide = field.hideIf ? field.hideIf({editTableProps, formState, watch}) : false;

  const [localWrite] = usePermissions(field.permissions);

  return (
    <>{!hide && 
      <Button
        size={field.size ?? "sm"}
        variant={field.variant ?? "outline-secondary"}
        onClick={async () => {
            let result = null;
            setIsAwaiting(true);
            try{
              result = await field?.handler({ watch, setValue, getValues, editTableProps, methods: formState, handleSubmit, onSubmit, redirect, modalContext, confirm, loadData: field.loadData });
            } catch {

            } finally{
              setIsAwaiting(false);
            }
            if(result && typeof field?.icon === 'function')
            {
              const iconResult = await field?.icon({ handlerResult: result });
              if(iconResult)
              {
                setDynamicIcon(iconResult);
              }
            }
            return result;
        }}
        disabled={isAwaiting || (field.adminOnly && !isAdmin) || !writeAccess || (field.disabled ? field.disabled({ watch }, entity) : false) || !localWrite}
        style={{ ...(field.style || {}) }}
      >
        {dynamicIcon && <i className={`bi bi-${dynamicIcon.icon}`} style={dynamicIcon.iconStyle} />}
        {field.label}
        {isAwaiting && <Spinner animation="border" size="sm" />}
      </Button>
      }
    </>
  );
}