import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactGrid, Column, Row, CellChange, TextCell } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import "./Spreadsheet.scss";
import { sum } from "../../../../services/array-helpers";
import { getRows, getColumns } from "./SpreadsheetUtility";
import useResizeObserver from "use-resize-observer";

var option = {
  style: 'percent',
  maximumFractionDigits: 0
};

var percentFormatter = new Intl.NumberFormat('en-US', option as any);

export const Spreadsheet = ({ field: spreadsheet, repository, formState }) => {
  const [editedCells, setEditedCells] = useState({});
  const { partitionView, cells, selector, disabledEdit, totals } = spreadsheet;
  const { dataSource: { pk, name: dataSourceName, requestArgs, entityRequestArgs }, fetchData } = repository;
  const { setValue, register } = formState;
  const data = useSelector((state: any) => state[dataSourceName].current?.data ? state[dataSourceName].current?.data[spreadsheet.name] : []);
  const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();


  useEffect(() => {
    setRecords(data)
    setValue(spreadsheet.name, data)
    setEditedCells({});
/*     setValue(spreadsheet.name + '_META', {rows, columns}) */
  }, [data])

  const [records, setRecords] = React.useState<any[]>(data);
  const [columns, setColumns] = React.useState<Column[]>(getColumns(spreadsheet, width));

  useEffect(() => {
    if(width){
      const c = getColumns(spreadsheet, width);
      setColumns(c);
    }
  }, [width])

  const rows = useMemo(() => records ? getRows(records, spreadsheet, formState, repository.dataSource, editedCells) : [], [records, formState]);

  const handleColumnResize = (ci: any, width: number) => {
    setColumns((prevColumns) => {
      const columnIndex = prevColumns.findIndex(el => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      prevColumns[columnIndex] = updatedColumn;
      return [...prevColumns];
    });
  }

  const applyChangesToRecords = (
    changes: CellChange<any>[],
    prevRecords: any[]
  ): any[] => {
    const clone = JSON.parse(JSON.stringify(prevRecords));
    changes.forEach((change) => {
      const personIndex = change.rowId as number + 1;
      const fieldName = change.columnId;
      const original = rows[personIndex].original;
      // const clonedRecord = prevRecords.find(x => x === original);
      const idxOfClone = prevRecords.indexOf(original);
      clone[idxOfClone][fieldName] = change.newCell.type == 'text' ? change.newCell.text : change.newCell.value;
    });
    const editClone = JSON.parse(JSON.stringify(editedCells));
    changes.forEach(c => {
      editClone[c.rowId] = editClone[c.rowId] || {};
      editClone[c.rowId][c.columnId] = true;
    });
    setEditedCells(editClone);
    setValue(spreadsheet.name, clone, { shouldDirty: true });
    return clone;
  };

  const handleChanges = (changes: CellChange<TextCell>[]) => {
    setRecords((prevRecords) => applyChangesToRecords(changes, prevRecords));
  };



  return (
    data && <div ref={ref}><ReactGrid
      
      disableVirtualScrolling
      stickyTopRows={1}
      stickyBottomRows={totals?.length || 0}
      rows={rows}
      columns={columns}
      enableRangeSelection
      enableColumnSelection
      enableRowSelection
      onCellsChanged={handleChanges}
      onColumnResized={handleColumnResize}
      enableFillHandle
    /></div>
  );
}