import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Repository } from "./data-source";
import { ListViewColumn } from "./list-view";
import { EntityAttachmentsProps } from './FormItems/entity-attachments';
import { EntityInfoPaneProps } from './FormItems/entity-info-pane';
import { useLocation } from 'react-router-dom';
import { getError } from "../../../services/form-helpers";
import { selectCurentUserRoles, selectIsAdmin } from "../../../store/slices/auth-slice";
import { EditTablePartitionProps } from "./FormItems/EditTable";
import { TextField } from "./FormItems/TextField";
import "./form-view.scss";
import { ModalViewProps } from "./modal-view";
import { InlineField } from "./FormItems/InlineField";
import { CustomField } from "./FormItems/CustomField";
import { FormItemType, componentMap } from "./component-map";
import useConfirm from "../organisms/ConfirmDialog/use-confirm";

export interface FormFieldValidation {
  required?: string;
}

export interface SelectOption {
  id: string;
  text: string;
}

export interface FormFieldComponentProps {
  onChange: (val: any) => void;
  initialValue?: any;
}

export interface ChangeEventProps {
  watch: any;
  setValue: any;
  getValue?: any;
  newValue: any;
  editMode: boolean;
}

export interface ModalProps {
  name: string,
}

export interface FormItemProps {
  editTableProps,
  setValue,
  watch,
  field,
  control,
  fieldErrors,
  validation,
  getValues,
  data,
  rowIdentity,
  isUnique,
  required,
  register,
  errors,
  formState
}

export interface FormField {
  label?: string;
  name?: string;
  validation?: any;
  required?: boolean | ((p: { watch }) => boolean);
  options?: SelectOption[] | ((state: any) => any[]) | string[] | ((data: any) => Promise<any[]>);
  unique?: boolean;
  component?: (p: FormFieldComponentProps | any) => JSX.Element;
  componentArgs?: any;
  onChange?: any;//(changeEventProps: ChangeEventProps) => void;
  disabled?: (p: { watch }) => boolean;
  freeform?: boolean; //typeahead
/*   listViewProps?: ListViewProps; */
  handler?: (event: any) => void;
  tooltip?: string;
  number?: boolean;
  defaultValue?: any;
  fitToContent?: boolean;
  labelWidth?: number;
  width?: number;
  minWidth?: number;
  min?: number;
  adminOnly?: boolean;
  partitionView?: EditTablePartitionProps[];
  modal?: ModalViewProps;
  selectable?: boolean;
  hideIf?: ({ editTableProps, formState, watch, getValues }) => boolean,
  maxLength?: number,
  minLength?: number,
  emptyText?: string,
  value?: (state) => any,
  inline?: boolean,
  flexHeight?: boolean,
  filters?: any,
  cells?: any,
  horizontalScroll?: boolean,
  badgeCounter?: (state: any) => number;
  allowGrow?: boolean;
  allowScroll?: boolean;
  infoListItems?: any[];
  addTemplate?: any;
  style?: any;
  onAfterSubmit?: (meta: any) => void;
  tableSchema?: string;
  format?: 'currency' | 'number';
  computedValue?: (getValues, editTableProps) => any;
  variant?: string;
  mountOnEnter?: boolean;
  isFluid?: boolean,
  unMountOnExit?: boolean,
  size?: string,
  hideAddButton?: boolean,
  header?: any,
  getComponent?: () => any,
  height?: any,
  dataSelector?: any
}

export interface FormItem extends FormField {
  hide?: (p: { watch }) => void;
  type?: FormItemType,
  items?: (FormItem)[],
  headerItems?: (FormItem)[],
  footerItems?: (FormItem)[],
  columns?: ListViewColumn[],
  selector?: (state) => any,
  loadData?: () => void
};

export interface FormViewTab extends FormView {
  tabLabel: any;
}
export interface FormViewBreadcrumbs {
  name: string,
  icon: string,
  to: string
}
export interface FormView {
  pageHeader?: string,
  breadcrumbs?: FormViewBreadcrumbs[],
  items?: FormItem[];
  info?: FormView;
  onUpdate?: (form: any) => void;
  formState?: any;
  editMode?: boolean;
  adminOnly?: boolean;
  permissions?: {read: number[], write: number[], admin: number[]};
  modalSize?: 'lg' | 'xl' | 'sm';
}

export type ComponentType = FormItem & EntityAttachmentsProps & EntityInfoPaneProps;

export const createFormView = (formView: FormView, repository?: Repository) => {
  return () => {
    const { confirm }  = useConfirm();
    const location = useLocation();
    const { items, formState, editMode, permissions } = formView;
    const { register, unregister, control, watch, formState: { errors, isDirty, touchedFields }, setValue, handleSubmit, onSubmit, getValues, modalContext } = formState;
    const { dataSource: { pk, name } } = repository;
    const data = useSelector((state) => state[name].data);
    const entity = useSelector((state) => state[name].current.data);
    const entityStatus = useSelector((state) => state[name].current.status);
    const fetchStatus = useSelector((state: any) => state[name].status);
    const createStatus = useSelector((state: any) => state[name].createAction.status);
    const updateStatus = useSelector((state: any) => state[name].updateAction.status);
    const currRoles = useSelector(selectCurentUserRoles) as any;
    const writeAccess = useMemo(() => {
      if(!permissions || !permissions.write || !currRoles) return true;
      const readRoles = [...currRoles, {roleId: 2}].find(r => permissions.write.includes(r.roleId));
      return readRoles;
    }, [currRoles, permissions]);
    const entityLoading = entityStatus === 'loading' || createStatus === 'loading' || updateStatus === 'loading' || fetchStatus === 'loading';
    const isAdminRole = useSelector(selectIsAdmin);
    const isAdmin = isAdminRole;

    //  const { permissions, entityName, entityPK, entityTypeId, append, readOnly, adminOnly } = field;
    const renderFormField = (field: ComponentType) => {

      const wrapWithTooltip = (content, f) => {
        return content;
      }

      let componentRender = null;

      const renderFormItemInnerInner = (field?: FormItem, rowIdentity?: any, editTableProps?: any) => {
        const { validation } = field;
        let fieldErrors = getError(errors, field.name, rowIdentity);
        const required = typeof field.required === 'function' ? field.required({ watch }) : field.required;
        const isUnique = (val: string) => {
          const entityPkValue = entity ? entity[pk] : null;
          return !data.find(existing => {
            return existing[pk] != entityPkValue
              && ((typeof existing[field.name] == 'string' && existing[field.name]?.toLowerCase() === val?.toLowerCase())
                || (typeof existing[field.name] == 'number' && existing[field.name] == val))
          });
        }

        const entityPk = entity ? entity[pk] : null;
        const fieldPropSet = {
          confirm, modalContext, pk, entityPk, format: field.format, onSubmit, handleSubmit, entityLoading, editMode, writeAccess, isAdmin, repository, formView, entity, renderFormItem: RenderFormItem, renderItem: renderFormItemInnerInner, field, control, errors, fieldErrors, validation, getValues, data, rowIdentity, isUnique, required, register, unregister, formState, watch, setValue, editTableProps
        }
        const MappedComponent = typeof field.type != 'undefined' && componentMap[field.type] ? componentMap[field.type] : null;

        if (typeof field.type === 'undefined' && field.items?.length) {
          componentRender = field.items.map(item => {
            return renderFormItemInnerInner(item as any);
          });
        }
        else if (field.inline) {
          componentRender = (
            <InlineField {...fieldPropSet} />
          )
        }
        else if (field.type === FormItemType.customComponent) {
          const CustomComponent = field.getComponent ? field.getComponent() : field.component;
          const { onChange: onCustomFieldChange } = field;
          componentRender = (
            <CustomComponent
              onChange={(newValue) => onCustomFieldChange({ watch, setValue, newValue, editMode })}
              {...(field.componentArgs || {})}
              {...fieldPropSet}
              fieldProps={fieldPropSet}
            />
          );
        }
        else if (field.type === FormItemType.customField) {
          return (
            <CustomField {...fieldPropSet} fieldProps={fieldPropSet} />
          )
        }
        else if (field.type === FormItemType.textarea || field.type === FormItemType.text || typeof field.type == 'undefined') {
          componentRender = (
            <TextField {...fieldPropSet} />
          );
        }
        else{
          componentRender = <MappedComponent {...fieldPropSet} />; 
        }

        return componentRender;
      }

      renderFormItemInnerInner(field);

      return wrapWithTooltip(componentRender,field);
    }

    const RenderFormItem = ({ item }: { item: any }) => {
      const hidden = item?.hide ? item.hide({ watch }) : false;

      if (!hidden) {
        return renderFormField(item);
      }
      return <span style={{ display: 'none' }}>
        {renderFormField(item)}
      </span>;
    };

    return items.map(item => {
      return RenderFormItem({ item });
    });

  }
}