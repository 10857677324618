import { getAlarmRules, getAlarmWorldMap, getKeyLocations, insertAlarmRule, insertKeyLocation, updateAlarmRule, updateKeyLocation } from "../../../services/requests/alarm/alarmrequests";
import { DataSource } from "../../UI/view/data-source";

export const alarmDataSource = {
  name: 'alarms',
  pk: 'alarmRuleId',
  request: getAlarmRules,
  updateRequest: updateAlarmRule,
  createRequest: insertAlarmRule,
} as DataSource;


export const alarmMapDataSource = {
  name: 'alarmworldmap',
  pk: 'id',
  request: getAlarmWorldMap,
} as DataSource;


export const alarmKeyLocationsDataSource = {
  name: 'akeylocations',
  pk: 'id',
  request: getKeyLocations,
  updateRequest: updateKeyLocation,
  createRequest: insertKeyLocation,
} as DataSource;

