import { getAssetProperties } from "../../../services/requests/alarm/alarmrequests";
import { FormItemType } from "../../UI/view/component-map";
import { FormView } from "../../UI/view/form-view";
import { createConnectedListView } from "../../UI/view/list-view";
import { createConnectedView } from "../../UI/view/view";
import { DewarCustomerSelectList } from "../Customers/customer-dewar-list";
import { GasCustomerSelectList } from "../Customers/customer-gas-list";
import { LiquidCustomerSelectList } from "../Customers/customer-liquid-list";
import { alarmDataSource } from "./alarm-datasource";

export const alarmRuleCreate = {
  allowGrow: true,
  items: [
    {
      type: FormItemType.boundingBox,
      height: 500,
      items: [
        {
          type: FormItemType.fieldset,
          allowGrow: true,
          label: 'Alarm Rule',
          items: [
            {
              type: FormItemType.select,
              required: true,
              label: 'Product Type',
              name: 'assetType',
              defaultValue: null,
              fitToContent: true,
              width: 200,
              options: [
                {
                  id: null,
                  text: ''
                },
                {
                  id: 'Liquid',
                  text: 'Liquid'
                },
                {
                  id: 'Gas',
                  text: 'Gas'
                },
                {
                  id: 'Dewar',
                  text: 'Packaged Product'
                },
              ]
            },
            {
              type: FormItemType.label,
              label: 'Condition',
              hideIf: (myThing) => {
                return !myThing.watch('assetType')
              },
            },
            {
              type: FormItemType.row,
              hideIf: (myThing) => {
                return !myThing.watch('assetType')
              },
              items: [
                {
                  type: FormItemType.customTextarea,
                  name: 'alarmCondition',
                  options: async ({ getValues }) => {
                    const productType = getValues('assetType');
                    const result = getAssetProperties(productType);
                    return result;
                  },
                  required: true
                },
                {
                  type: FormItemType.button,
                  label: 'Validate',
                  fitToContent: true,
                  handler: async ({ getValues, watch, redirect, confirm }) => {
                    //call validation endpoint
                    //return value is either valid or invalid
                  },
                  icon: ({ validationErrors }) => {
                    if (validationErrors) {
                      
                    }
                  }
                }
              ]
            },
            {
              name: 'geofenceDistance',
              label: 'Geofence Distance (meters)',
              number: true,
            },
            {
              name: 'geofenceLatitude',
              label: 'Geofence Latitude',
              number: true,
            },
            {
              name: 'geofenceLongitude',
              label: 'Geofence Longitude',
              number: true,
            },
            {
              name: 'alarmRuleType',
              defaultValue: 'Geofence',
              hideIf: () => true
            },
            {
              name: 'isActive',
              defaultValue: true,
              hideIf: () => true
            }


/*             public int? GeofenceDistance { get; set; }
            public double? GeofenceLatitude { get; set; }
            public double? GeofenceLongitude { get; set; }
        
            
            public int? EnterThreshold { get; set; }
            public int? ExitThreshold { get; set; } */

            /*         {
                      type: FormItemType.label,
                      hideIf: (myThing) => myThing.watch('productType'),
                      label: 'Please first pick a product type'
                    },
                    {
                      type: FormItemType.boundingBox,
                      height: 500,
                      items: [
                        {
                          type: FormItemType.customField,
                          style: {height: '100%', paddingBottom: 20},
                          required: true,
                          name: 'dewarCustomerId',
                          label: 'Packaged Product Customer',
                          component: DewarCustomerSelectList,
                          hideIf: (myThing) => {
                            return myThing.watch('productType') != 'Dewar'
                          },
                        },
                        {
                          type: FormItemType.customField,
                          style: {height: '100%', paddingBottom: 20},
                          required: true,
                          name: 'liquidCustomerId',
                          label: 'Liquid Customer',
                          component: LiquidCustomerSelectList,
                          hideIf: (myThing) => {
                            return myThing.watch('productType') != 'Liquid'
                          },
                        },
                        {
                          type: FormItemType.customField,
                          style: {height: '100%', paddingBottom: 20},
                          required: true,
                          name: 'gasCustomerId',
                          label: 'Gas Customer',
                          component: GasCustomerSelectList,
                          hideIf: (myThing) => {
                            return myThing.watch('productType') != 'Gas'
                          },
                        },
                      ]
                    }, */


          ]
        }
      ]
    }
  ]
} as FormView;

export const AlarmRuleCreate = createConnectedView({ form: alarmRuleCreate, dataSource: alarmDataSource });