import './sidebar.scss';

import React, { useMemo, useState } from 'react';
import { Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import defaultProfile from '../../../../assets/images/0.png';
import {
  selectAccount, selectCurentUserRoles,
  selectIsAdmin, selectIsMaintenanceManager, selectLoggedInUserInfo,
} from '../../../../store/slices/auth-slice';
import { usePermissions } from '../../../hooks/usePermissions';
import { alarmPermissions } from '../../../pages/Alarm/alarm-permissions';
import { billingPermissions } from '../../../pages/Billing/billing-datasource';
import { forecastingPermissions } from '../../../pages/DemandForecasting/shipments-datasource';
import Balloons from '../../atoms/Balloons/balloons';
import SideBarButton from '../../atoms/SidebarButton/sidebar-button';
import { SidebarHoverButton } from '../../atoms/SidebarButton/sidebar-hover-button';
import { SignOutButton } from '../../molecules/Signout/signout';

function SideBar(props) {
  const account = useSelector(selectAccount);
  const isAdmin = useSelector(selectIsAdmin);
  const currRoles = useSelector(selectCurentUserRoles);
  const [_, forecastingAccess] = usePermissions(forecastingPermissions);
  const [_2, billingAccess] = usePermissions(billingPermissions);
  const [_3, alarmAccess] = usePermissions(alarmPermissions);
  const isMaintenanceManager = useSelector(selectIsMaintenanceManager);
  const userInfo = useSelector(selectLoggedInUserInfo);
  const isAffiliate = currRoles?.find((r) => r.roleId === 23) ?? false;
  const [openMenus, setOpenMenus] = useState([]);

  const menuToggle = (menuText) => {
    if (openMenus.includes(menuText)) {
      setOpenMenus(openMenus.filter((m) => m !== menuText));
    } else {
      setOpenMenus([...openMenus, menuText]);
    }
  };

  const menuItems = useMemo(
    () => {
      const resultArray = [
        { menuText: 'Home', route: '/', icon: 'house-door' },
        { menuText: 'Assets', route: '/assets', icon: 'minecart' },
        { menuText: 'Customers', route: '/customers', icon: 'people' },
        { menuText: 'Locations', route: '/locations', icon: 'building' },
        { menuText: 'Gas On-Site', route: '/telemetry', icon: 'router' },
      ];

      // This should be in the future only visible to maintenance users.
      // { menuText: 'Locations', route: '/locations', icon: 'building' },
      const maintenanceItem = {
        menuText: 'Maintenance',
        route: '#',
        icon: 'wrench-adjustable',
        children: [
          { menuText: 'Dashboard', route: 'maintenance', icon: 'layout-text-window-reverse' },
          { menuText: 'Request Types', route: 'maintenance/management', icon: 'clipboard2-plus' },
          { menuText: 'Parts Inventory', route: 'maintenance/parts-inventory', icon: 'tools' },
          { menuText: 'Purchase Requests', route: 'maintenance/purchase-requests', icon: 'receipt' },
          { menuText: 'Purchase Orders', route: 'maintenance/purchase-order', icon: 'receipt-cutoff' },
          { menuText: 'Vendors', route: 'maintenance/vendors', icon: 'shop' },
        ],
      };
      resultArray.push(maintenanceItem);

      if (isAdmin) {
        const adminItem = {
          menuText: 'Admin',
          route: '#',
          icon: 'gear-wide',
          children: [
            { menuText: 'Users', route: '/admin/users', icon: 'person-circle' },
            { menuText: 'Roles', route: '/admin/roles', icon: 'key' },
            { menuText: 'Enum', route: '/admin/enum', icon: 'key' },
            { menuText: 'Dev Controls', route: '/admin/dev-controls', icon: 'key' },
          ],
        };

        const auditLog = {
          menuText: 'Audit Log',
          route: '/audit-log',
          icon: 'file-text',
        };
        resultArray.push(adminItem);
        resultArray.push(auditLog);
      }

      if (forecastingAccess) {
        const forecasting = {
          menuText: 'Demand Forecasting',
          route: '/globaldemand',
          icon: 'cloud-sun',
        };
        resultArray.push(forecasting);
      }
      if (alarmAccess) {
        const alarm = {
          menuText: 'Alarms',
          route: '/alarm/main',
          icon: 'alarm',
        };
        resultArray.push(alarm);
      }
      if (billingAccess) {
        const billingToplevel = {
          menuText: 'Billing',
          route: '#',
          icon: 'currency-dollar',
          /*           children: [
                      { menuText: 'Misc. Billing', route: '/billing/misc', icon: 'currency-dollar' },
                      {
                        menuText: 'Liquid Billing',
                        route: '/billing/liquidproduct',
                        icon: 'currency-dollar',
                      },
                      { menuText: 'Liquid Rentals', route: '/billing/liquid', icon: 'currency-dollar' },
                      {
                        menuText: 'Gas Billing',
                        route: '/billing/gasproduct',
                        icon: 'currency-dollar',
                      },
                      { menuText: 'Gas Rentals', route: '/billing/gas', icon: 'currency-dollar' },
                      {
                        menuText: 'Dewar Billing',
                        route: '/billing/dewarproduct',
                        icon: 'currency-dollar',
                      },
                      { menuText: 'Dewar Rentals', route: '/billing/dewar', icon: 'currency-dollar' },
                    ], */
          /*           children: [
                      { menuText: 'Misc. Billing', route: '/billing/misc', icon: 'currency-dollar' },
                      {
                        menuText: 'Liquid Billing',
                        route: '/billing/liquid',
                        icon: 'currency-dollar',
                        children: [
                          { menuText: 'Liquid Rentals', route: '/billing/liquid', icon: 'currency-dollar' },
                        ],
                      },
                      {
                        menuText: 'Gas Billing',
                        route: '/billing/gas',
                        icon: 'currency-dollar',
                        children: [
                          { menuText: 'Gas Rentals', route: '/billing/gas', icon: 'currency-dollar' },
                        ],
                      },
                      {
                        menuText: 'Dewar Billing',
                        route: '/billing/dewar',
                        icon: 'currency-dollar',
                        children: [
                          { menuText: 'Dewar Rentals', route: '/billing/dewar', icon: 'currency-dollar' },
                        ],
                      },

                    ], */
          /*           children: [
            {
              menuText: 'Rental',
              route: '#',
              icon: 'currency-dollar',
              children: [
                { menuText: 'Liquid Rentals', route: '/billing/liquid', icon: 'currency-dollar' },
                { menuText: 'Gas Rentals', route: '/billing/gas', icon: 'currency-dollar' },
                { menuText: 'Dewar Rentals', route: '/billing/dewar', icon: 'currency-dollar' },
              ],
            },
            {
              menuText: 'Product',
              route: '#',
              icon: 'currency-dollar',
              children: [
                {
                  menuText: 'Liquid Billing',
                  route: '/billing/liquidproduct',
                  icon: 'currency-dollar',
                },
                {
                  menuText: 'Gas Billing',
                  route: '/billing/gasproduct',
                  icon: 'currency-dollar',
                },
                {
                  menuText: 'Dewar Billing',
                  route: '/billing/dewarproduct',
                  icon: 'currency-dollar',
                },
                { menuText: 'Misc. Billing', route: '/billing/misc', icon: 'currency-dollar' },
              ],
            },
          ], */
          children: [
            { menuText: 'Misc. Billing', route: '/billing/misc', icon: 'currency-dollar' },

            { menuText: 'Liquid Rentals', route: '/billing/liquid', icon: 'currency-dollar' },

            { menuText: 'Gas Rentals', route: '/billing/gas', icon: 'currency-dollar' },
            { menuText: 'Gas', route: '/billing/gasproduct', icon: 'currency-dollar' },

            { menuText: 'Dewar Rentals', route: '/billing/dewar', icon: 'currency-dollar' },
            { menuText: 'Dewar', route: '/billing/dewarproduct', icon: 'currency-dollar' },
          ],
        };
        resultArray.push(billingToplevel);
      }

      return resultArray;
    },
    [account, isAdmin, forecastingAccess, billingAccess],
  );

  return (
    <aside className="main-sidebar elevation-4 sidebar-dark-primary">
      <NavLink to="/" className="d-flex brand-link align-items-center">
        <Balloons className="elevation-3" />
        <span className="brand-text ml-2">
          Linde
          {' '}
          <sup>3</sup>
          He
        </span>
      </NavLink>
      <div className="sidebar">
        <div className="user-panel pt-3 pb-2 mb-2" style={{ borderBottomWidth: '1px' }}>
          <NavLink
            to="/profile"
            title="Click here to go to your profile settings"
            className={({ isActive }) => `d-flex ${isActive && props.to !== '#' ? 'profile-active' : 'notactive'}`}
          >
            <div className="profile-wrapper user-panel">
              <div className="image">
                <Image src={defaultProfile} roundedCircle />
              </div>
              <div className="info">
                {userInfo ? userInfo.displayName : 'Unknown'}
              </div>
            </div>
          </NavLink>
        </div>
        <nav>
          <ul className="nav nav-pills nav-sidebar flex-column">
            {(!isAffiliate ? menuItems : [
              {
                menuText: 'Demand Forecasting',
                route: '/globaldemand',
                icon: 'cloud-sun',
              },
            ]).map((item, index) => (

              <li
                key={`sidebar-item-${index}`}
                className={`nav-item${openMenus.includes(item.menuText) ? ' menu-open' : ''}`}
              >
                <SideBarButton
                  icon={item.icon}
                  text={item.menuText}
                  to={item.route}
                  onClick={item?.children ? () => menuToggle(item.menuText) : () => ({})}
                  hasSubMenu={!!item?.children}
                />
                {item?.children && (
                  <ul className={`maintenance-submenu nav nav-treeview${openMenus.includes(item.menuText) ? ' menu-is-open' : ''}`}>

                    {
                      item.children.map((subItem, subIndex) => (
                        <li key={`sidebar-subitem-${subIndex}`} className={subItem?.children ? `nav-item${openMenus.includes(subItem.menuText) ? ' menu-open' : ''}` : 'nav-item'}>
                          {item.hover ? (
                            <SidebarHoverButton
                              icon={subItem.icon}
                              text={subItem.menuText}
                              to={subItem.route}
                              hasSubMenu={!!subItem?.children}
                              onClick={subItem?.children ? () => menuToggle(subItem.menuText) : () => ({})}
                            />
                          ) : (
                            <SideBarButton
                              icon={subItem.icon}
                              text={subItem.menuText}
                              to={subItem.route}
                              hasSubMenu={!!subItem?.children}
                              onClick={subItem?.children ? () => menuToggle(subItem.menuText) : () => ({})}
                            />
                          )}
                          {subItem?.children && (
                            <ul className={`maintenance-submenu nav nav-treeview${openMenus.includes(subItem.menuText) ? ' menu-is-open' : ''}`}>
                              {
                                subItem.children.map((subItem2, subIndex2) => (
                                  <li key={`sidebar-subitem-${subIndex2}`} className="nav-item">
                                    {!subItem2?.children && (
                                      <SideBarButton
                                        icon={subItem2.icon}
                                        text={subItem2.menuText}
                                        to={subItem2.route}
                                      />
                                    )}
                                  </li>
                                ))
                              }
                            </ul>

                          )}
                        </li>
                      ))
                    }
                  </ul>
                )}
              </li>

            ))}

            {process.env.REACT_APP_ENV === 'prd' && (
              <li
                key="logout-item"
                className="nav-item"
              >
                <a
                  className="nav-link"
                  href="https://vts.linde.grp/Helium/VTS/Menu.aspx"
                >
                  <i className="bi-back nav-icon fas ml-1" />
                  {' '}
                  <p className="text-nowrap">
                    VTS
                  </p>

                </a>
              </li>
            )}
            <li
              key="logout-item-1"
              className="nav-item"
            >
              <SignOutButton />
            </li>

          </ul>
        </nav>
      </div>
    </aside>
  );
}

export default SideBar;
