import { FormItemType } from "../../UI/view/component-map";
import { FormView } from "../../UI/view/form-view";
import { createConnectedListView, ListView, ListViewColumnType } from "../../UI/view/list-view";
import { createConnectedView } from "../../UI/view/view";
import { alarmDataSource } from "./alarm-datasource";
import { alarmRuleCreate } from "./alarm-rule-create";

export const alarmRuleList = {
  create: alarmRuleCreate,
  update: alarmRuleCreate,
  variant: 'card',
  columns: [
    {
      type: ListViewColumnType.editdelete
    },
    {
      accessor: 'alarmRuleId',
      Header: 'Alarm Rule Id'
    },
/*     {
      accessor: 'customerName',
      Header: 'Customer'
    }, */
    {
      accessor: 'alarmCondition',
      Header: 'Condition'
    },
    {
      accessor: 'geofenceLatitude',
      Header: 'Latitude'
    },
    {
      accessor: 'geofenceLongitude',
      Header: 'Longitude'
    },
  ]
} as ListView;

export const AlarmRuleList = createConnectedListView(alarmRuleList, alarmDataSource);


/* {
  name: 'geofenceDistance',
  label: 'Geofence Distance (meters)',
  number: true,
},
{
  name: 'geofenceLatitude',
  label: 'Geofence Latitude',
  number: true,
},
{
  name: 'geofenceLongitude',
  label: 'Geofence Longitude',
  number: true,
},
{
  name: 'alarmRuleType',
  defaultValue: 'Geofence',
  hideIf: () => true
} */



/* export const alarmRuleListPage = {
  pageHeader: 'Geofence Alarms',

  editMode: false,
  breadcrumbs: [
    {
      name: 'Geofence Alarms',
      icon: 'boundingBox',
      to: '/alarms/rules',
    },
  ],
  items: [
    {
      type: FormItemType.customComponent,
      component: AlarmRuleList
    }
  ]
} as FormView;

export const AlarmRuleListPage = createConnectedView({ form: alarmRuleListPage, dataSource: alarmDataSource }); */