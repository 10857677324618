
import { BoundingBox } from "./FormItems/BoundingBox";
import { CheckBox } from "./FormItems/CheckBox";
import { CheckField } from "./FormItems/CheckField";
import { ClassicCheckField } from "./FormItems/ClassicCheckField";
import { ConnectedChart } from "./FormItems/ConnectedChart";
import { Container } from "./FormItems/Container";
import { CustomTextarea } from "./FormItems/CustomTextarea";
import { DateField } from "./FormItems/DateField";
import DateRangePicker from "./FormItems/DateRangePicker";
import { EditTable } from "./FormItems/EditTable";
import { ExpressionField } from "./FormItems/ExpressionField";
import { FieldSet } from "./FormItems/FieldSet";
import { Footer } from "./FormItems/Footer";
import { FormButton } from "./FormItems/FormButton";
import { FormLabel } from "./FormItems/FormLabel";
import { FormLabelField } from "./FormItems/FormLabelField";
import { FormRow } from "./FormItems/FormRow";
import { SelectField } from "./FormItems/Select";
import { Spreadsheet } from "./FormItems/Spreadsheet";
import { SubmitButton } from "./FormItems/SubmitButton";
import { TabControl } from "./FormItems/TabControl";
import { TypeaheadField } from "./FormItems/Typeahead";
import { YN } from "./FormItems/YN";
import { YesNo } from "./FormItems/YesNo";
import { EntityAttachments } from "./FormItems/entity-attachments";
import EntityAuditLogs from "./FormItems/entity-audit-logs";
import { EntityInfoPane } from "./FormItems/entity-info-pane";
import { WorldMap } from "./FormItems/world-map";
import { MasterDetailLayout } from "./Layout/master-detail-layout";


export enum FormItemType {
  //layout
  tab, fieldset, row, masterDetailLayout, boundingBox, container,

  //form fields
  text, textarea, date, select, typeahead,
  checkbox, checkboxField, picklist, button, submitButton,
  label, yesno, yn, labeledValue, daterange, footer, checkboxClassic, 
  labelField, expressionBox, customTextarea,

  //custom
  customField, customComponent, fileAttachments, infoPane, auditLogs,

  //collections
  editTable, listview, cardList, spreadsheet,

  //etc
  chart, worldmap
}

export const componentMap = {
  [FormItemType.boundingBox]: BoundingBox,
  [FormItemType.tab]: TabControl,
  [FormItemType.fieldset]: FieldSet,
  [FormItemType.spreadsheet]: Spreadsheet,
  [FormItemType.row]: FormRow,
  [FormItemType.editTable]: EditTable,
  [FormItemType.label]: FormLabel,
  [FormItemType.auditLogs]: EntityAuditLogs,
  [FormItemType.submitButton]: SubmitButton,
  [FormItemType.infoPane]: EntityInfoPane,
  [FormItemType.fileAttachments]: EntityAttachments,
  [FormItemType.masterDetailLayout]: MasterDetailLayout,
  [FormItemType.button]: FormButton,
  [FormItemType.checkbox]: CheckBox,
  [FormItemType.checkboxField]: CheckField,
  [FormItemType.checkboxClassic]: ClassicCheckField,
  [FormItemType.select]: SelectField,
  [FormItemType.yesno]: YesNo,
  [FormItemType.yn]: YN,
  [FormItemType.typeahead]: TypeaheadField,
  [FormItemType.date]: DateField,
  [FormItemType.daterange]: DateRangePicker,
  [FormItemType.footer]: Footer,
  [FormItemType.container]: Container,
  [FormItemType.labelField]: FormLabelField,
  [FormItemType.chart]: ConnectedChart,
  [FormItemType.worldmap]: WorldMap,
  [FormItemType.expressionBox]: ExpressionField,
  [FormItemType.customTextarea]: CustomTextarea,
};