import React, { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap"
import DateInput from "../../molecules/DateInput/date-input";
import { useValidation } from "../../../hooks/useValidation";
import { BsInfoCircle } from "react-icons/bs";
import { FormItemType } from "../component-map";
import { formatFormValue } from "../../../../services/format";
import { usePermissions } from "../../../hooks/usePermissions";

export const TextField = ({format, setValue, watch, field, control, fieldErrors, validation, getValues, data, rowIdentity, isUnique, required, register, errors, formState, editTableProps}) => {
  const [customValidation, customValidatorNames] = useValidation({field, validation, getValues, data, rowIdentity, isUnique, errors });
  const {touchedFields} = formState?.formState;

  const isDirty = touchedFields && touchedFields[field.name]//;.find(x => x.name == field.name);
  const [localWrite] = usePermissions(field.permissions);
  const hide = field.hideIf ? field.hideIf({editTableProps, rowIdentity, watch}) : false;
  const renderTooltip = (props) => {
    return (
      <Tooltip id="button-tooltip" {...props} >
        {field.tooltip}
      </Tooltip>
    )
  }
  return !hide && (
    <Form.Group className={editTableProps ? undefined : "mb-1"}>
      {field.label && !field.tooltip && <Form.Label>{field.label}</Form.Label>}
      {field.label && field.tooltip && (
        <><Form.Label>{field.label}</Form.Label>
          <OverlayTrigger
            placement="top"
            delay={{ show: 0, hide: 0 }}
            overlay={renderTooltip}
          >
            <Button variant="link" style={{ backgroundColor: 'transparent', color: 'unset', padding: 0, paddingLeft: 6 }}><BsInfoCircle style={{ marginTop: -6 }} size={15} /></Button>
          </OverlayTrigger></>
      )
      }
      <Form.Control
        className={field.className}
        autoComplete="do-not-autofill"
        style={{minWidth: field.minWidth ? field.minWidth : undefined, background: isDirty ? '#e6edff' : undefined}}
        disabled={!localWrite || (field.disabled ? field.disabled({ watch, editTableProps }) : undefined)}
        readOnly={field.disabled ? field.disabled({ watch, editTableProps }) : undefined}
        size="sm"
        required={required}
        as={field.type == FormItemType.textarea ? "textarea" : undefined}
        {...register(field.name, {
          required: required && 'Required',
          min: typeof field.min != 'undefined' ? { value: field.min, message: 'must be greater than 0' } : undefined,
          validate: { ...customValidation },
          maxLength: field.maxLength,
          minLength: field.minLength
        })}
        onChange={field.onChange ? (e) => {
          field.onChange(e.target.value, getValues, setValue, editTableProps, formState);
        } : undefined}
        isInvalid={fieldErrors}
        formControlName={field.name}
        type={!format && field.number ? 'number' : undefined}
        defaultValue={field.computedValue ? field.computedValue(getValues, editTableProps, watch) : undefined}
        title={field.title}
        
      />
      {
        fieldErrors && fieldErrors.type === "unique" && <Form.Control.Feedback type="invalid">
          must be unique
        </Form.Control.Feedback>
      }
      {
        fieldErrors && fieldErrors.type === "min" && <Form.Control.Feedback type="invalid">
          {'must be greater than ' + field.min}
        </Form.Control.Feedback>
      }
      {
        fieldErrors && fieldErrors.type === "required" && <Form.Control.Feedback type="invalid">
          {'this field is required'}
        </Form.Control.Feedback>
      }
      {
        fieldErrors && (customValidatorNames as string[])?.includes(fieldErrors.type) && <Form.Control.Feedback type="invalid">
          {validation[fieldErrors.type].message}
        </Form.Control.Feedback>
      }
      {
        fieldErrors && fieldErrors.type === "maxLength" && <Form.Control.Feedback type="invalid">
          {'max length is ' + field.maxLength}
        </Form.Control.Feedback>
      }
      {
        fieldErrors && fieldErrors.type === "minLength" && <Form.Control.Feedback type="invalid">
          {'min length is ' + field.minLength}
        </Form.Control.Feedback>
      }
    </Form.Group >
  );
}