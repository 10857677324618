import React, { ReactElement, useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react";
import { Button, Card, Collapse, Form, InputGroup, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import PaginatedSelectTable from "./FormItems/paginated-select-table";
import { DataSource, QueryFilter, Repository, LogicalConnector, LogicalOperator, SortDirection, createReduxStore } from "./data-source";
import PageHeader from '../../UI/molecules/PageHeader/page-header';
import TableSearchFilter from '../../UI/atoms/TableSearchFilter/table-search-filter';
import { FormItem, FormView } from "./form-view";
import { ModalView } from "./modal-view";
import { BsPencilFill, BsXLg, BsPlusLg } from 'react-icons/bs';
import toast from 'react-hot-toast';
import LoadingContent from '../../UI/molecules/LoadingContent/loading-content';
import { NavLink, useParams } from 'react-router-dom';
import RowSelectTable from "../organisms/Table/row-select-table";
import { SelectFilter } from "../atoms/Filters/SelectFilter";
import { MultiSelectFilter } from "../atoms/Filters/MultiSelectFilter";
import debounce from 'lodash.debounce';
import useEffectDelta from '../../hooks/useEffectDelta';
import { selectCurentUserRoles, selectIsAdmin, selectLoggedInUserPreferences } from "../../../store/slices/auth-slice";
import { exportData, mergeData } from "../../../services/table-helpers";
import { formatDate, setDateAsUTC } from "../../../services/format";
import { guid } from "../../../services/form-helpers";
import { reduxReducer, setReduxReducer } from "../../../store/store";
import { useNavigate } from "react-router-dom";
import { GreenIcon, RedIcon, YelloIcon } from "../atoms/CircleFillIcon/circle-fill-icon";
import { FormModal } from "./FormItems/FormModal";
import { CellSpinner } from "./FormItems/CellSpinner";
import { FormItemType } from "./component-map";
import { IconContext } from "react-icons";

export type Sort = (a: any, b: any) => 0 | 1 | -1;

export interface RendererParamRow {
  original: any
}
export interface RendererParams {
  value: any,
  row: RendererParamRow
}
export type Renderer = (p: RendererParams) => any;

export interface ListViewBreadcrumbs {
  name: string,
  icon: string,
  to: string
}
export enum ListViewColumnType {
  edit, delete, editdelete, link, linkEdit, datetime, yesno, date, checkbox, radio, trafficLight, icon, button, formField
}
export interface ListViewColumn {
  show?: (viewOptions) => boolean;
  type?: ListViewColumnType,
  Header?: string,
  id?: string,
  accessor?: string | ((row: any) => any),
  disabled?: (row: any) => boolean,
  Cell?: (row: any) => any,
  sort?: Sort,
  renderer?: Renderer,
  linkPath?: (meta: any) => string,
  newLink?: boolean,
  defaultSort?: boolean,
  defaultSortDirection?: 'asc' | 'desc',
  hidden?: boolean,
  formItemProps?: FormItem,
  validation?: any,
  hideOverflow?: boolean,
  maxWidth?: number,
  serverExpression?: string,
  excludeGlobalFilter?: boolean,
  tooltip?: string | ((row: any) => any),
  disableSortBy?: boolean,
  icon?: any,
  iconColor?: string,
  buttonText?: string,
  onClick?: any
}
export interface ModalProps {
  form: FormView, repository: Repository, name: string, title?: string, defaults?: (row, additionalSets) => any
}
export interface ListView {
  columns: ListViewColumn[],
  filters?: FilterDefinition[],
  viewOptions?: ViewOption[],
  dataSourceSelectors?: ((state: any) => any)[],
  pageHeader?: string,
  breadcrumbs?: ListViewBreadcrumbs[],
  hideGlobalFilter?: boolean,
  create?: FormView,
  /*   onCreate?: Repository, */
  update?: FormView,
  onUpdate?: Repository,
  adminOnly?: boolean,
  enableExport?: boolean,
  toolbar?: any,
  variant?: 'card',
  noFetchOnMount?: boolean,
  defaultPageSize?: any,
  maxPageSize?: any,
  addButtonText?: string,
  selectionMode?: 'single' | 'multi',
  activeFlag?: string,
  noGlobalFilterPadding?: boolean,
  secondarySort?: string,
  permissions?: any,
  customExport?: any,
  autoCheckAll?: boolean,
  disableSelection?: boolean,
  additionalModals?: ModalProps[],
  preventFetch?: boolean,
  preventRedirectOnCreate?: boolean
  /*   modalSize?: "sm" | "lg" | "xl" */
}
export interface ListViewProps {
  onFilterChanged?: (filterText: string) => void;
  toolbarWidget?: ReactElement;
  onRowSelect?: (selection: any[]) => void,
  listView?: ListView,
  repository?: Repository,
  dataSelector?: any,
  fieldProps?: any
}
export enum FilterType {
  radio, combo, custom, hidden, simple, multiselect, text, number
};
export interface FilterProps {
  state: any, data: any[], value: any, filterState: any
}
export interface FilterDefinition {
  type: FilterType,
  fieldName: string;
  label?: string;
  options?: (state: any) => any[];
  labels?: any;
  filterValue?: any;
  number?: boolean;
  max?: number;
  serverExpression?: string;
  logicalConnector?: LogicalConnector;
  valExpression?: (val: any) => any;
  minValue?: number;
  maxValue?: number;
}
export interface ViewOption {
  type: FilterType,
  optionName: string;
  label?: string;
  options?: (state: any) => any[];
  defaultValue?: any;
}
export const createConnectedListView2 = (listView: ListView, dataSource?: DataSource, dataSelector?: any) => {
  const repository = createReduxStore(dataSource);
  reduxReducer[dataSource.name] = repository.reducer;
  setReduxReducer(reduxReducer);

  return [(props?: ListViewProps) => {
    return (
      <FrameWorkListView
        onFilterChanged={props.onFilterChanged}
        toolbarWidget={props.toolbarWidget}
        onRowSelect={props.onRowSelect}
        listView={listView}
        repository={repository}
        dataSelector={dataSelector}
        fieldProps={props.fieldProps}
      />
    )
  }, repository]
}
export const createConnectedListView = (listView: ListView, dataSource?: DataSource, dataSelector?: any) => {
  const repository = createReduxStore(dataSource);
  reduxReducer[dataSource.name] = repository.reducer;
  setReduxReducer(reduxReducer);

  return (props?: ListViewProps) => {
    return (
      <FrameWorkListView
        onFilterChanged={props.onFilterChanged}
        toolbarWidget={props.toolbarWidget}
        onRowSelect={props.onRowSelect}
        listView={listView}
        repository={repository}
        dataSelector={dataSelector}
        fieldProps={props.fieldProps}
      />
    )
  }
}
export const createListView = (listView: ListView, repository?: Repository, dataSelector?: any) => {
  return (props?: ListViewProps) => {
    return (
      <FrameWorkListView
        onFilterChanged={props.onFilterChanged}
        toolbarWidget={props.toolbarWidget}
        onRowSelect={props.onRowSelect}
        listView={listView}
        repository={repository}
        dataSelector={dataSelector}
        fieldProps={props.fieldProps}
      />
    )

  }
}

export const FrameWorkListView = (props: ListViewProps) => {
  const { onFilterChanged, toolbarWidget, onRowSelect, listView, repository, dataSelector, fieldProps } = props;
  const { permissions, customExport, autoCheckAll, disableSelection } = listView;
  const [tableKey, setTableKey] = useState(guid());
  const redirect = useNavigate();
  const currRoles = useSelector(selectCurentUserRoles) as any;
  const writeAccess = useMemo(() => {
    if (!permissions || !permissions.write || !currRoles) return true;
    const readRoles = currRoles.find(r => permissions.write.includes(r.roleId));
    return readRoles;
  }, [currRoles, permissions]);

  const {
    dataSourceSelectors,
    columns,
    pageHeader,
    breadcrumbs,
    hideGlobalFilter,
    create,
    update,
    filters,
    adminOnly,
    enableExport,
    viewOptions,
    noFetchOnMount,
    defaultPageSize,
    maxPageSize,
    addButtonText,
    selectionMode,
    activeFlag,
    noGlobalFilterPadding,
    secondarySort,
    additionalModals,
    preventFetch,
    preventRedirectOnCreate
    /*     modalSize */
  } = listView;
  const {
    dataSource: { pk, name: dataSourceName, selector, serverSideEvents, entityRequest, requestArgs, additionalRequests },
    fetchData,
    createAction,
    updateAction,
    actions: { setCurrent, setCreateStatus, setUpdateStatus },
    additionalActions
  } = repository;

  const data = useSelector((state: any) => state[dataSourceName].data);
  const selectedData = useSelector((state: any) => dataSelector ? dataSelector(state) : null);

  const [currSelection, setCurrSelection] = useState([]);

  const getData = useMemo(() => {
    return (dataSelector ? selectedData : data) || [];
  }, [selectedData, data]);

  useEffect(() => {
    const theData = getData;
    if (autoCheckAll && !disableSelection) {
      setCurrSelection(theData)
    }
    else if (disableSelection) {
      fieldProps.setValue(fieldProps.field.name, theData)
    }
  }, [getData]);
  useEffect(() => {
    if (fieldProps && fieldProps?.field?.name && !disableSelection) {
      if (selectionMode == 'single') {
        fieldProps.setValue(fieldProps.field.name, currSelection?.length ? currSelection[0][pk] : null)
      } else {
        fieldProps.setValue(fieldProps.field.name, currSelection)
      }
    }
  }, [currSelection])
  const fetchStatus = useSelector((state: any) => state[dataSourceName].status);
  const { filter: filterRaw } = useParams();
  const filter = filterRaw ? JSON.parse(decodeURIComponent(filterRaw)) : {};
  const [searchText, setSearchText] = useState('');
  const [pageState, setPageState] = useState({
    pageIndex: 0,
    pageSize: defaultPageSize || 25,
  });
  const isAdminRole = useSelector(selectIsAdmin);
  const isAdmin = isAdminRole;
  const requestArgsSelector = useSelector((state: any) => requestArgs ? requestArgs(state) : null);
  const createdEntity = useSelector((state: any) => state[dataSourceName].createAction.createdEntity);
  const createError = useSelector((state: any) => state[dataSourceName].createAction.error);
  const createStatus = useSelector((state: any) => state[dataSourceName].createAction.status);
  const updateStatus = useSelector((state: any) => state[dataSourceName].updateAction.status);
  const [showModalAdd, setModalShowAdd] = useState(false);
  const [showModalEdit, setModalShowEdit] = useState(false);
  const [additionalModal, setShowAdditionalModal] = useState(false as (ModalProps | boolean));

  const dataStatus = useSelector((state: any) => {
    const base = state[dataSourceName];
    const status = base.status;
    return status;
  });
  const dispatch = useDispatch();

  const loadData = useCallback(async () => {
    if (preventFetch) return;
    if (serverSideEvents) {
      dispatch(fetchData({
        page: pageState.pageIndex,
        limit: pageState.pageSize,
        sortDefinition: getSort(),
        filters: generateFilters(),
      }));
    } else {
      dispatch(fetchData(requestArgsSelector));
    }

    /*       } */
  }, [dataStatus, dispatch]);

  useEffect(() => {
    if (additionalActions && additionalRequests && getData?.length) {
      additionalRequests.forEach(act => {
        dispatch(additionalActions[act.name](getData));
      })
    }
  }, [getData]);

  useEffect(() => {
    if (!noFetchOnMount || fetchStatus == 'idle') {
      loadData();
    }
  }, []);

  const tableInstance = useRef(null);
  const tableRef = tableInstance.current;
  //const [currentPage, tableRef, tableInstance] = useTablePageRef();

  const initFilterState = React.useMemo(() => filters?.reduce((obj, current, idx) => {
    switch (current.type) {
      case FilterType.text:
      case FilterType.number:
        obj[current.fieldName] = current.filterValue;
        break;
      case FilterType.combo:
        obj[current.fieldName] = current.filterValue;
        break;
      case FilterType.multiselect:
        obj[current.fieldName] = current.filterValue;
        break;
      default:
        obj[current.fieldName] = filter[current.fieldName] ?? current.filterValue ?? true;
    }
    /*       obj[current.fieldName] = typeof current.filterValue != 'undefined' ? current.filterValue : ([FilterType.combo, FilterType.multiselect].includes(current.type) ? null : true); */
    return obj;
  }, {}), [filters]);

  const initViewOptionState = React.useMemo(() => viewOptions?.reduce((obj, current, idx) => {
    obj[current.optionName] = current.defaultValue;
    return obj;
  }, {}), [viewOptions]);


  const handleFilterChange = (value, filterId) => {
    const filter = filters?.find(f => f.fieldName === filterId);
    switch (filter?.type) {
      case FilterType.simple:
      case FilterType.radio:
        if (!serverSideEvents) {
          tableRef.setFilter(filterId, !filtersState[filterId]);
        }
        dispatchFilters({ type: filterId });
        return;
      case FilterType.multiselect:
        if (!serverSideEvents) {
          tableRef.setFilter(filterId, value);
        }
      case FilterType.combo:
        if (!serverSideEvents) {
          tableRef.setFilter(filterId, value == '' ? undefined : value);
        }
        dispatchFilters({ type: filterId, value: value });
        return;
      case FilterType.number:
      case FilterType.text:
        if (!serverSideEvents) {
          tableRef.setFilter(filterId, value == '' ? undefined : value);
        }
        dispatchFilters({ type: filterId, value: value });
        return;
      default:
        return;
    }
  };
  const handlerViewOptionChange = (value, filterId) => {
    dispatchViewOptions({ type: filterId });
  };

  function filtersReducer(state, action) {
    const fieldName = action.type;
    const setVal = action.value ?? !state[action.type];
    const filter = filters?.find(f => f.fieldName === fieldName);
    switch (typeof filter?.type == 'undefined' ? FilterType.simple : filter?.type) {
      case FilterType.simple:
      case FilterType.radio:
        return { ...state, [fieldName]: setVal };
      case FilterType.multiselect:
      case FilterType.combo:
        return { ...state, [fieldName]: action.value };
      case FilterType.text:
      case FilterType.number:
        return { ...state, [fieldName]: action.value };
      default:
        return;
    }
  };
  function viewOptionsReducer(state, action) {
    const fieldName = action.type;
    const setVal = action.value ?? !state[action.type];
    const filter = viewOptions?.find(f => f.optionName === fieldName);
    switch (typeof filter?.type == 'undefined' ? FilterType.simple : filter?.type) {
      case FilterType.simple:
      case FilterType.radio:
        return { ...state, [fieldName]: setVal };
      case FilterType.multiselect:
      case FilterType.combo:
        return { ...state, [fieldName]: action.value };
      default:
        return;
    }
  };
  const [filtersState, dispatchFilters] = useReducer(filtersReducer, { filterOpen: false, ...initFilterState });
  const [viewOptionsState, dispatchViewOptions] = useReducer(viewOptionsReducer, { viewOptionsOpen: false, ...initViewOptionState });

  const setDefaultFilters = () => {
    Object.keys(filtersState).forEach((filterId) => {
      const filter = filters?.find(f => f.fieldName === filterId);
      if (filterId !== 'filterOpen' && filtersState[filterId] === true) {
        if (tableRef != null) {
          if (!serverSideEvents) {
            tableRef.setFilter(filterId, typeof filter.filterValue == 'undefined' ? false : filter.filterValue);
          }
        }
      }
      if (filterId !== 'filterOpen' && typeof filtersState[filterId] != 'undefined' && filter.type == FilterType.combo) {
        if (tableRef != null) {
          if (!serverSideEvents) {
            tableRef.setFilter(filterId, typeof filter.filterValue == 'undefined' ? false : filter.filterValue);
          }
        }
      }
    });
  };

  const tableColumns = useMemo(
    () => columns
    ,
    [],
  );

  const additionalSets = useSelector((state: any) => {
    const sets = {};
    additionalRequests?.forEach(req => {
      const isLoading = state[dataSourceName][req.name].status == 'loading';
      const d = state[dataSourceName][req.name].data;
      sets[req.name] = isLoading ? null : d;
    });
    return sets;
  });

  const total = useSelector((state: any) => state[dataSourceName].total);
  const numPages = Math.ceil((total ?? 0) / (pageState?.pageSize ?? 1));



  useEffect(() => {
    setDefaultFilters();
  }, [tableRef, getData]);

  const createSuccessNotify = () => toast.success('Successfully Saved', { id: 'savesuccess' });
  const createFailureNotify = (error) => toast.error(error ? `Failed to Save: ${error}` : `Failed to Save`, { id: 'savefailure' });

  useEffect(() => {
    if (createStatus === 'succeeded') {
      if (create) {
        createSuccessNotify()
        dispatch(setCreateStatus('idle'));
        setModalShowAdd(false);
        const c = columns?.find(c => c?.type == ListViewColumnType.linkEdit && c?.linkPath);
        if (c && !preventRedirectOnCreate) {
          const editPath = c?.linkPath({ value: createdEntity[(c?.id || c?.accessor) as string], row: { original: createdEntity } });
          if (editPath) {
            redirect(editPath);
          }
        }
      }
    } else if (createStatus === 'failed') {
      createFailureNotify(createError);
      dispatch(setCreateStatus('idle'));
    }
    if (updateStatus === 'succeeded') {
      if (update) {
        createSuccessNotify();
        dispatch(setUpdateStatus('idle'));
        setModalShowEdit(false);
      }
    }
  }, [createStatus, updateStatus, createdEntity]);

  const {
    datePreference
  } = useSelector(selectLoggedInUserPreferences) as any;

  const validationMsg = (message, row) => {
    if (typeof message == 'function') {
      return message(row)
    }
    return message;
  }
  const validate = (row, colDef, content) => {
    if (content == 'loading...') return content;
    if (colDef.validation) {
      const failedValidations = Object.keys(colDef.validation).filter(v => {
        const { handler } = colDef.validation[v];
        const passes = handler(row);
        return !passes;
      }).map(v => ({ validator: v, message: validationMsg(colDef.validation[v].message, row), color: colDef.validation[v].color }));
      const passed = !failedValidations?.length;
      const title = !passed ? failedValidations[0].message : undefined;
      if (passed) return content;
      return (
        <div style={{ color: !passed ? (failedValidations[0].color || 'red') : undefined }} title={title}>
          {content}
        </div>
      )
    }
    return content;
  }

  const parseColumns = (cols) => {
    return cols.map(col => {
      if (col.type === ListViewColumnType.trafficLight) {
        return {
          ...col,
          Cell: ({ value, row }) => {
            const status = value;
            if (status === 'Red') {
              return <RedIcon tooltip={col.tooltip && col.tooltip(row.original)} />;
            }
            if (status === 'Yellow') {
              return <YelloIcon tooltip={col.tooltip && col.tooltip(row.original)} />;
            }
            if (status === 'Green') {
              return <GreenIcon tooltip={col.tooltip && col.tooltip(row.original)} />;
            }
            return '';
          },
        }
      }
      if (col.type === ListViewColumnType.button) {
        return {
          ...col,
          fixedWidth: 52,
          disableSortBy: true,
          Cell: ({ value, row }) => {
            const status = value;
            const ColIcon = typeof col.icon == 'function' ? col.icon(row, additionalSets) : col.icon;
            return (
              <Button
                size={"xsm" as any}
                title={col.tooltip}
                variant={col.variant && col.variant(row, additionalSets)}
                disabled={!writeAccess || (col.disabled ? col.disabled(row, additionalSets) : undefined)}
                onClick={async () => {
                  if (col.onClick) {
                    col.onClick()
                  }
                  if (col.modal) {
                    const theModal = additionalModals.find(m => m.name == col.modal(row, additionalSets));
                    if (theModal) {
                      const { actions: { setCurrent } } = (theModal as ModalProps).repository;
                      if ((theModal as ModalProps).defaults) {
                        const defs = await (theModal as ModalProps).defaults(row, additionalSets);
                        dispatch(setCurrent(defs));
                      }
                      setShowAdditionalModal(theModal)
                    }
                  }
                }}
                className="mr-1"
              >
                {ColIcon ? <ColIcon /> : col.buttonText}
              </Button>
            )
            return '';
          },
        }
      }
      if (col.type === ListViewColumnType.icon) {
        return {
          ...col,
          fixedWidth: 52,
          Cell: ({ value, row }) => {
            const status = value;
            return (<i className={`bi bi-${status}`} style={col.iconStyle && col.iconStyle(row.original)} title={col.tooltip && col.tooltip(row.original)} />)
          },
        }
      }
      if (col.type === ListViewColumnType.radio) {
        return {
          ...col,
          Cell: ({ value, row }) => <input type="radio" style={{ transform: 'scale(1.2)' }} checked={currSelection?.length && currSelection[0][pk] === row.original[pk]} onChange={(e) => {
            setCurrSelection([row.original])
          }} disabled={col.disabled ? col.disabled(row.original) : false} />,
        }
      }
      if (col.type === ListViewColumnType.checkbox) {
        return {
          ...col,
          disableSortBy: true,
          fixedWidth: 52,
          Cell: ({ value, row }) => (
            <input
              type="checkbox"
              style={{ transform: 'scale(1.2)' }}
              disabled={col.disabled ? col.disabled(row.original) : false}
              checked={currSelection?.length && currSelection.find(r => r[pk] == row.original[pk])}
              onChange={(e) => {
                if (e.target.checked) {
                  setCurrSelection([...currSelection, row.original])
                } else {
                  setCurrSelection([...currSelection.filter(r => r[pk] != row.original[pk])])
                }
                //setCurrSelection([...currSelection, row.original])
              }}
            />
          ),
          Header: () => {
            return (
              <input
                type="checkbox"
                style={{ transform: 'scale(1.2)' }}
                checked={(getData).filter(r => !col.disabled || !col.disabled(r))?.length == currSelection?.length && currSelection?.length >= 1}
                onChange={(e) => {
                  const myData = (getData);
                  const enabledRows = myData.filter(r => !col.disabled || !col.disabled(r))
                  if (e.target.checked) {
                    //select all
                    setCurrSelection([...enabledRows])
                  } else {
                    //deselect all
                    setCurrSelection([])
                  }
                }}
              />
            )
          }
        }
      }
      if (col.type === ListViewColumnType.yesno) {
        return {
          ...col,
          Cell: ({ value, row }) => value ? 'Yes' : 'No',
        }
      }
      if (col.type === ListViewColumnType.datetime) {
        return {
          ...col,
          accessor: typeof col.accessor == 'function' ? (row) => {
            return col.accessor(row, additionalSets)
          } : col.accessor,
          Cell: ({ value, row }) => {
            return validate({ ...row.original, ...row.values }, col, value == 'loading...' ? value : !value ? '' : formatDate(setDateAsUTC(value), datePreference));
          }
        }
      }
      if (col.type === ListViewColumnType.date) {
        return {
          ...col,
          accessor: typeof col.accessor == 'function' ? (row) => {
            return col.accessor(row, additionalSets)
          } : col.accessor,
          Cell: ({ value, row }) => {
            return validate({ ...row.original, ...row.values }, col, value == 'loading...' ? value : !value ? '' : formatDate(value, datePreference));
          }
        }
      }
      if (col.type === ListViewColumnType.link) {
        return {
          ...col,
          accessor: typeof col.accessor == 'function' ? (row) => {
            return col.accessor(row, additionalSets)
          } : col.accessor,
          Cell: ({ value, row }) => (<NavLink target={col.newLink && "_blank"} to={col.linkPath({ value, row })}>{col.icon ?? value}</NavLink>),
        };
      }
      if (col.type === ListViewColumnType.linkEdit) {
        return {
          ...col,
          Cell: ({ value, row }) => (<NavLink onClick={() => {
            if (!entityRequest) {
              dispatch(setCurrent(row.original));
            } else {
              dispatch(setCurrent(null));
            }
          }} to={col.linkPath({ value, row })}>{value}</NavLink>),
        };
      }
      if (col.type === ListViewColumnType.delete) {
        return {
          Header: '',
          id: 'deleteColumn',
          fixedWidth: 52,
          Cell: ({ cell }) => (
            <div className="d-flex">
              <Button
                disabled={!writeAccess}
                // @ts-ignore
                size="xsm"
                variant={cell.row.original.isActive ? 'danger' : 'success'}
                title={cell.row.original.isActive ? 'Deactivate' : 'Activate'}
                onClick={() => {
                  if (cell.row.original.isActive) {
                    if (confirm("Are you sure you want to deactivate?") == true) {
                      dispatch(updateAction({ ...cell.row.original, isActive: false }))
                    }
                  } else {
                    dispatch(
                      updateAction({ ...cell.row.original, isActive: true })
                    )
                  }
                }}
              >
                {cell.row.original.isActive ? <BsXLg /> : <BsPlusLg />}
              </Button>
            </div>
          ),
        };
      }
      if (col.type === ListViewColumnType.editdelete) {
        return {
          Header: '',
          id: 'actionColumn',
          fixedWidth: 52,
          Cell: ({ cell }) => (
            <div className="d-flex">
              <Button
                // @ts-ignore
                size="xsm"
                title="Edit"
                disabled={!writeAccess}
                onClick={() => {
                  dispatch(setCurrent(cell.row.original));
                  setModalShowEdit(true);
                }}
              >
                <BsPencilFill />
              </Button>
              &nbsp;
              <Button
                // @ts-ignore
                size="xsm"
                disabled={!writeAccess}
                variant={(activeFlag ? cell.row.original[activeFlag] : cell.row.original.isActive) ? 'danger' : 'success'}
                title={(activeFlag ? cell.row.original[activeFlag] : cell.row.original.isActive) ? 'Deactivate' : 'Activate'}
                onClick={() => ((activeFlag ? cell.row.original[activeFlag] : cell.row.original.isActive)
                  ? dispatch(updateAction({ ...cell.row.original, [activeFlag ? activeFlag : 'isActive']: false }))
                  : dispatch(
                    updateAction({ ...cell.row.original, [activeFlag ? activeFlag : 'isActive']: true })
                  ))}
              >
                {(activeFlag ? cell.row.original[activeFlag] : cell.row.original.isActive) ? <BsXLg /> : <BsPlusLg />}
              </Button>
            </div>
          ),
        };
      } else if (col.validation) {
        return {
          ...col,
          accessor: typeof col.accessor == 'function' ? (row) => {
            return col.accessor(row, additionalSets)
          } : col.accessor,
          Cell: (args) => {
            return validate({ ...args.row.original, ...args.row.values }, col, col.Cell ? col.Cell(args) : args.value);
          },
          filter: hasMultiFilter(col) ? (rows: any[], columnIds: string[], filterValue) => {
            const id = col.id ?? col.accessor;
            return rows.filter(row => filterValue[row.values[id]]);
          } : col.filter
        }
      }
      const overflowHide = col.hideOverflow ? { whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: col.maxWidth ? col.maxWidth : '50px' } : {};
      return (
        {
          ...col,
          ...(col.Cell ? { Cell: (({ value, row }) => (!col.loading || !col.loading({ value, row }, additionalSets) ? col.Cell({ value, row }, additionalSets) : <CellSpinner />)) } : {}),
          title: col.hideOverflow ? (c, b) => {
            return b.values[col.accessor];
          } : (col.tooltip ? (c, b) => col.tooltip(b.values) : col.title),
          style: {
            ...col.style,
            ...overflowHide
          },
          accessor: typeof col.accessor == 'function' ? (row) => {
            return col.accessor(row, additionalSets)
          } : col.accessor,
          filter: hasMultiFilter(col) ? (rows: any[], columnIds: string[], filterValue) => {
            const id = col.id ?? col.accessor;
            return rows.filter(row => filterValue[row.values[id]]);
          } : col.filter
        }
      );
    })
  }

  const hiddenColumns = useMemo(() => columns.filter(c => c.hidden || (c.show && !c.show(viewOptionsState))).map(h => (h.id ?? h.accessor)), [columns, viewOptionsState]);

  useEffect(() => {
    setTableKey(guid());
  }, [JSON.stringify(hiddenColumns)]);

  const hasMultiFilter = (col: any) => {
    const id = col.id ?? col.accessor;
    return filters?.find(f => f.fieldName === id)?.type == FilterType.multiselect;
  }

  const parsedColumns = useMemo(() => {
    return parseColumns(tableColumns);
  }, [tableColumns, additionalSets]);

  const defaultSortCol = parsedColumns.find(x => x.defaultSort);
  const defaultSort = defaultSortCol?.id || defaultSortCol?.accessor;
  const defaultSortDirection = parsedColumns.find(x => x.defaultSort)?.defaultSortDirection;
  const [sortState, setSortState] = useState([{
    id: defaultSort,
    desc: defaultSortDirection == 'desc'
  }]);

  const tableProps = {
    key: tableKey,
    onPaginationChange: (args) => {
      setPageState(args);
      if (args.pageIndex != pageState.pageIndex || args.pageSize != pageState.pageSize) {
        dispatch(fetchData({
          sortDefinition: getSort(),
          filters: generateFilters(),
          page: args.pageIndex,
          limit: args.pageSize
        }));
      }
    },
    onSortingChange: (args) => {
      if (serverSideEvents && args?.length === 0) {
        setSortState([{
          id: defaultSort,
          desc: defaultSortDirection == 'desc'
        }])
      } else {
        setSortState(args);
      }
    },
    defaultPageSize: defaultPageSize,
    maxPageSize: maxPageSize,
    pageCount: serverSideEvents ? numPages : undefined,
    manualSorting: serverSideEvents,
    manualPagination: serverSideEvents,
    manualGlobalFilter: serverSideEvents,
    onDataFiltered: onFilterChanged,
    onRowSelect: onRowSelect,
    singleSelectMode: true,
    columns: parsedColumns,
    data: getData,
    ref: tableInstance,
    rowProps: () => ({}),
    initialState: {
      hiddenColumns: hiddenColumns,
      sortBy: defaultSort ? [
        {
          id: defaultSort,
          desc: defaultSortDirection == 'desc'
        }
      ] : []
    },
  };
  const table = (
    onRowSelect ?
      <RowSelectTable
        {...tableProps}
      /> :
      <PaginatedSelectTable
        {...tableProps}
      />
  );
  const breadcrumbsMemo = useMemo(() => breadcrumbs, []);

  const toggleModalAdd = () => {
    setModalShowAdd(true);
  }
  const onGlobalSearch = (newSearchText) => {
    if (!serverSideEvents)
      return;
    setSearchText(newSearchText);
  };
  const debouncedChangeHandler = useCallback(
    debounce(onGlobalSearch, 300)
    , []);

  const generateFilters = () => {
    let filtersArray = [] as QueryFilter[];
    if (searchText && searchText.length > 2) {
      let globalFilter = columns.filter(c => !c.excludeGlobalFilter).map(column => ({
        accessor: column.serverExpression || column.accessor,
        logicalConnector: LogicalConnector.Or,
        value: searchText,
        logicalOperator: LogicalOperator.Like
      })) as QueryFilter[];
      filtersArray.push({
        filters: globalFilter
      });
    }
    let filterCount = 0;
    Object.keys(filtersState).filter(f => f != 'filterOpen').forEach((filter, fIdx) => {
      const filterDefinition = filters.find(f => f.fieldName == filter);
      if (filterDefinition.type == FilterType.multiselect) {
        const filterValue = filtersState[filter];
        let multiFilter = Object.keys(filterValue).filter(k => filterValue[k]).map(d => ({
          accessor: filterDefinition.fieldName,
          logicalConnector: LogicalConnector.Or,
          value: d,
          logicalOperator: LogicalOperator.Equal
        }))
        if (multiFilter?.length > 0) {
          filtersArray.push({
            filters: multiFilter,
            /*             logicalConnector: LogicalConnector.And, */
          });
        }
      } else {
        const filterValue = filtersState[filter];
        if (filterValue) {
          filtersArray.push({
            accessor: filterDefinition.serverExpression ? getServerExpression(filterDefinition, filterValue) : filter,
            logicalConnector: typeof filterDefinition.logicalConnector != 'undefined' && filterCount != 0 ? filterDefinition.logicalConnector : LogicalConnector.And,
            value: filterValue,
            logicalOperator: LogicalOperator.Equal,
            stringExpression: !!filterDefinition.serverExpression
          });
          filterCount++;
        }
      }
    })
    return filtersArray;
  }

  const getServerExpression = (filter: FilterDefinition, value: any) => {
    if (filter.type == FilterType.text || filter.type == FilterType.number) {
      return filter.serverExpression.replace("@val", filter.valExpression ? filter.valExpression(value) : value);
    }
    return filter.serverExpression;
  }

  const getSort = () => {
    if (sortState?.length) {
      const sortId = sortState[0].id;
      const sortCol = columns.find(c => (c.id || c.accessor) === sortId);
      const sortExpression = sortCol.serverExpression || sortId;
      return (
        {
          path: sortExpression,
          direction: sortState[0].desc ? SortDirection.Desc : SortDirection.Asc,
          secondarySort
        }
      );
    }
    return undefined;
  }

  useEffectDelta(() => {
    setCurrSelection([])
    if (!serverSideEvents)
      return;

    tableInstance?.current.gotoPage(0);
    setPageState({
      pageIndex: 0,
      pageSize: pageState.pageSize
    })

    dispatch(fetchData({
      sortDefinition: getSort(),
      filters: generateFilters(),
      page: 0,
      limit: pageState.pageSize
    }));

  }, [searchText, JSON.stringify(Object.keys(filtersState).filter(f => f != 'filterOpen').map(f => filtersState[f])), JSON.stringify(sortState)])

  const renderFilter = (filter: FilterDefinition, key: number) => {
    switch (filter.type) {
      case FilterType.simple:
      case FilterType.radio:
        return (
          <Form.Check
            key={key}
            type="switch"
            className="mb-1"
            inline
            label={filter.label}
            checked={filtersState[filter.fieldName]}
            onChange={(e) => handleFilterChange(e, filter.fieldName)}
          />
        );
      case FilterType.combo:
        return (<SelectFilter defaultValue={filtersState[filter.fieldName]} data={getData} key={key} filter={filter} handleFilterChange={handleFilterChange} selector={filter.options} />);
      case FilterType.multiselect:
        return (<MultiSelectFilter key={key} filtersState={filtersState} filter={filter} handleFilterChange={handleFilterChange} selector={filter.options} />);
      case FilterType.text:
      case FilterType.number:
        return (
          <Row className="uiRow">
            <div style={{ display: 'inline-flex' }}>
              {filter.label && <Form.Label style={{ marginTop: 6, marginRight: 6, whiteSpace: 'nowrap' }}>{filter.label}</Form.Label>}
              <Form.Control size="sm" max={filter.maxValue} min={filter.minValue} type={filter.type == FilterType.number ? "number" : undefined} key={key} defaultValue={filtersState[filter.fieldName]} onChange={debounce((e) => handleFilterChange(e.target.value, filter.fieldName), 300)} />
            </div>
          </Row>
        )
      default:
        throw new Error('invalid case');
    }
  }
  const renderViewOption = (viewOption: ViewOption, key: number) => {
    switch (viewOption.type) {
      case FilterType.simple:
      case FilterType.radio:
        return (
          <Form.Check
            key={key}
            type="switch"
            className="mb-1"
            inline
            label={viewOption.label}
            checked={viewOptionsState[viewOption.optionName]}
            onChange={(e) => handlerViewOptionChange(e, viewOption.optionName)}
          />
        );
      default:
        throw new Error('invalid case');
    }
  }

  const onExport = () => {
    const rows = tableRef?.globalFilteredRows?.map(r => r.original);

    if (customExport) {
      let headers = Object.keys(rows[0]) as any;
      const orderedHeaders = [...new Set(parsedColumns.filter((h) => typeof h.accessor === 'function' || headers.includes(h.id ?? h.accessor)).map((col) => col.id ?? col.accessor))];
      headers = [
        ...orderedHeaders,
        ...headers.filter((h) => !orderedHeaders.includes(h)),
      ];
      const mergedData = mergeData(rows, headers, parsedColumns, additionalSets);
      customExport(mergedData);
      return;
    }
    exportData('data', rows, parsedColumns, additionalSets);
  };

  const isField = noGlobalFilterPadding;

  const toolBarContent = (
    <>
      <div className="card-tools" style={!isField ? undefined : { marginLeft: 0, marginRight: 0, marginTop: 0 }}>
        {(!hideGlobalFilter || create) && <div className="card-tools" style={!isField ? undefined : { marginLeft: 0, marginRight: 0, marginTop: 0 }}>

          <InputGroup size="sm">
            <>
              {!hideGlobalFilter && <>
                {!filters && <InputGroup.Text><i className="bi bi-search fas" /></InputGroup.Text>}
                {filters && <Button
                  data-cy="open-filters"
                  variant={filtersState.filterOpen ? 'primary' : 'outline-primary'}
                  onClick={() => {
                    dispatchFilters({ type: 'filterOpen' })
                    dispatchViewOptions({ type: 'viewOptionsOpen', value: false })
                  }}
                >
                  <i className={filtersState.filterOpen ? 'bi bi-funnel-fill' : 'bi bi-funnel'} />
                </Button>}
                {viewOptions && <Button
                  data-cy="open-viewoptions"
                  variant={viewOptionsState.viewOptionsOpen ? 'primary' : 'outline-primary'}
                  onClick={() => {
                    dispatchViewOptions({ type: 'viewOptionsOpen', value: !viewOptionsState.viewOptionsOpen })
                    dispatchFilters({ type: 'filterOpen', value: false })
                  }}
                >
                  <i className={'bi-sliders2'} />
                </Button>}
                <TableSearchFilter onFilterChanged={debouncedChangeHandler} tableInstance={tableInstance} />
              </>}
              {enableExport && <Button variant="primary" size="sm" onClick={onExport}><i className="bi bi-download" /></Button>}
              {props.listView.toolbar && props.listView.toolbar?.map(btn => {
                if (btn.type == FormItemType.button && btn.modal) {
                  const TheButton = fieldProps.renderItem({
                    ...btn,
                    handler: () => {
                      const theModal = additionalModals.find(m => m.name == btn.modal);
                      if (theModal) {
                        setShowAdditionalModal(theModal)
                      }
                    }
                  });
                  return TheButton;
                }
                else if (fieldProps) {
                  return fieldProps.renderItem({ ...btn, loadData });
                }
                return (
                  <Button variant="primary" size="sm" onClick={btn.handler}>{btn.label}</Button>
                );
              })}
              &nbsp;
              {create && (!adminOnly || isAdmin) && <Button disabled={!writeAccess} variant="primary" size="sm" onClick={toggleModalAdd}>{addButtonText || 'Add'}</Button>}
              {toolbarWidget ? toolbarWidget : undefined}
            </>
          </InputGroup>
          {filters?.length && <Collapse in={filtersState.filterOpen}>
            <div>
              <div className="p-3 d-flex flex-wrap wrap">
                {
                  filters.map((f, idx) => renderFilter(f, idx))
                }
              </div>
            </div>
          </Collapse>}
          {viewOptions?.length && <Collapse in={viewOptionsState.viewOptionsOpen}>
            <div>
              <div className="p-3 d-flex flex-wrap wrap">
                {
                  viewOptions.map((f, idx) => renderViewOption(f, idx))
                }
              </div>
            </div>
          </Collapse>}
        </div>}
      </div>
      {table}
    </>
  );


  const container = (
    <>
      {pageHeader && <PageHeader header={pageHeader} breadcrumbs={breadcrumbsMemo} />}
      <div className={`d-flex flex-column flex-grow-1 h-100`}>
        {
          props.listView.variant == 'card' ? (
            <div className={`d-flex flex-column flex-grow-1 h-100`}>
              <Card className="card-primary card-outline flex-grow-1 overflow-auto">
                {toolBarContent}
              </Card>
            </div>


          ) : toolBarContent
        }


        {showModalAdd && <ModalView
          title="Add"
          show={showModalAdd}
          onModalHide={() => setModalShowAdd(false)}
          form={create}
          submitAction={createAction}
          repository={repository}
        /*           modalSize={modalSize} */
        />}
        {showModalEdit && <ModalView
          title="Edit"
          show={showModalEdit}
          onModalHide={() => setModalShowEdit(false)}
          form={update}
          submitAction={updateAction}
          editMode={true}
          repository={repository}
        />}
        {additionalModal && <FormModal
          form={(additionalModal as ModalProps).form}
          repository={(additionalModal as ModalProps).repository}
          title={(additionalModal as ModalProps).title}
          onModalHide={(doReload) => {
            setShowAdditionalModal(false);
            if (doReload) loadData();
          }}
          show={additionalModal}
        />}
        {dataStatus === 'loading' && !dataSelector && <LoadingContent />}
      </div>
    </>
  )

  return (
    props.listView.variant == 'card' ?
      (
        <div className="h-100 m-0 p-0 d-flex flex-column mb-2 container-fluid">
          {container}
        </div>
      ) : container
  );
}



