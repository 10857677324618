import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import {
  createPartDetailsAttachmentRequest,
  createPartRequest, deletePart,
  deletePartDetailsAttachment,
  getPart, getParts, reactivatePartRequest, updatePartRequest,
} from '../../services/requests';
import { selectLoggedInUserActiveLocation } from './auth-slice';
import { bulkUpdateAndCompleteMaintenanceRequestLineItems, undoMaintenanceRequestLineItem, updateMaintenanceRequestLineItemStatus } from './maintenance-request-line-items-slice';
import { selectCurrentMaintenanceRequestTypeForTemplateEditData } from './maintenance-requests-slice';

const initialState = {
  parts: {},
  partsFetch: {
    status: 'idle',
    error: null,
  },
  currentPart: {
    partId: null,
  },
  partFetchSingle: {
    status: 'idle',
    error: null,
  },
  locationFetch: {
    status: 'idle',
    error: null,
  },
  partCreate: {
    status: 'idle',
    error: null,
  },
  partRemove: {
    status: 'idle',
    error: null,
  },
  partUpdate: {
    status: 'idle',
    error: null,
  },
  partAttachmentRemove: {
    status: 'idle',
    error: null,
  },
  partAttachmentCreate: {
    status: 'idle',
    error: null,
  },
  reactivatePart: {
    status: 'idle',
    error: null,
  },
};

export const fetchParts = createAsyncThunk('parts/fetchParts', async (locationId) => {
  const response = await getParts(locationId);
  return response;
});

export const createPart = createAsyncThunk('parts/createPart', async (data) => {
  const response = await createPartRequest(data);
  return response;
});

export const removePart = createAsyncThunk('parts/deletePart', async (partId) => {
  const response = await deletePart(partId);
  return response;
});

export const fetchPart = createAsyncThunk('parts/fetchPart', async (partId) => {
  const response = await getPart(partId);
  return response;
});

export const updatePart = createAsyncThunk('parts/updatePart', async (data, thunkAPI) => {
  const response = await updatePartRequest(data);
  return response;
});

export const reactivatePart = createAsyncThunk('parts/reactivatePart', async (partId) => {
  const response = await reactivatePartRequest(partId);
  return response;
});

export const createPartDetailsAttachment = createAsyncThunk('attachment/createPartDetailsAttachment', async (attachment) => {
  const response = await createPartDetailsAttachmentRequest(attachment);
  return response;
});

export const removePartDetailsAttachment = createAsyncThunk('attachment/deletePartDetailsAttachment', async (attachmentId) => {
  const response = await deletePartDetailsAttachment(attachmentId);
  return response;
});

export const partsSlice = createSlice({
  name: 'part',
  initialState,
  reducers: {
    setCurrentPartId: (state, action) => {
      state.currentPart.partId = action.payload;
    },
    setPartUpdateStatus: (state, action) => {
      state.partUpdate.status = action.payload;
    },
    setReactivatePartStatus: (state, action) => {
      state.reactivatePart.status = action.payload;
    },
    setPartCreateStatus: (state, action) => {
      state.partCreate.status = action.payload;
    },
    setPartFetchStatus: (state, action) => {
      state.partsFetch.status = action.payload;
    },
    setPartFetchSingleStatus: (state, action) => {
      state.partFetchSingle.status = action.payload;
    },
    setRemoveAttachmentStatus: (state, action) => {
      state.partAttachmentRemove.status = action.payload;
    },
    setCreateAttachmentStatus: (state, action) => {
      state.partAttachmentCreate.status = action.payload;
    },

  },
  extraReducers(builder) {
    builder
      .addCase(fetchParts.pending, (state, action) => {
        state.partsFetch.status = 'loading';
      })
      .addCase(fetchParts.fulfilled, (state, action) => {
        state.partsFetch.status = 'succeeded';
        state.parts = action.payload.reduce((current, w) => ({
          ...current,
          [w.partId]: w,
        }), {});
        state.partsFetch.error = null;
      })
      .addCase(fetchParts.rejected, (state, action) => {
        state.partsFetch.status = 'failed';
        state.partsFetch.error = action.error.message;
      })
      .addCase(fetchPart.pending, (state, action) => {
        state.partFetchSingle.status = 'loading';
      })
      .addCase(fetchPart.fulfilled, (state, action) => {
        state.partFetchSingle.status = 'succeeded';
        state.parts[action.payload.partId] = action.payload;
        state.currentPart.partId = action.payload.partId;
        state.partFetchSingle.error = null;
      })
      .addCase(fetchPart.rejected, (state, action) => {
        state.partFetchSingle.status = 'failed';
        state.partFetchSingle.error = action.error.message;
      })
      .addCase(updatePart.pending, (state, action) => {
        state.partUpdate.status = 'loading';
      })
      .addCase(updatePart.fulfilled, (state, action) => {
        state.partUpdate.status = 'succeeded';
        state.parts[action.payload.partId] = action.payload;
        state.currentPart.partId = action.payload.partId;
        state.partUpdate.error = null;
      })
      .addCase(updatePart.rejected, (state, action) => {
        state.partUpdate.status = 'failed';
        state.partUpdate.error = action.error.message;
      });
    builder
      .addCase(reactivatePart.pending, (state, action) => {
        state.reactivatePart.status = 'loading';
      })
      .addCase(reactivatePart.fulfilled, (state, action) => {
        state.reactivatePart.status = 'succeeded';
        state.parts[action.payload.partId] = action.payload;
        state.currentPart.partId = action.payload.partId;
        state.reactivatePart.error = null;
      })
      .addCase(reactivatePart.rejected, (state, action) => {
        state.reactivatePart.status = 'failed';
        state.reactivatePart.error = action.error.message;
      });
    builder
      .addCase(createPart.pending, (state, action) => {
        state.partCreate.status = 'loading';
      })
      .addCase(createPart.fulfilled, (state, action) => {
        state.partCreate.status = 'succeeded';
        state.parts = { ...state.parts, [action.payload.partId]: action.payload };
        state.partCreate.error = null;
      })
      .addCase(createPart.rejected, (state, action) => {
        state.partCreate.status = 'failed';
        state.partCreate.error = action.error.message;
      });
    builder
      .addCase(removePart.pending, (state, action) => {
        state.partRemove.status = 'loading';
      })
      .addCase(removePart.fulfilled, (state, action) => {
        state.partRemove.status = 'succeeded';
        state.parts[action.payload.partId] = action.payload;
        state.partRemove.error = null;
      })
      .addCase(removePart.rejected, (state, action) => {
        state.partRemove.status = 'failed';
        state.partRemove.status = action.error.message;
      });

    builder.addCase(updateMaintenanceRequestLineItemStatus.fulfilled, (state, action) => {
      if (action.payload?.part?.partId) {
        state.parts[action.payload?.part?.partId] = action.payload?.part;
      }
    });
    builder.addCase(bulkUpdateAndCompleteMaintenanceRequestLineItems.fulfilled, (state, action) => {
      if (action.payload?.length) {
        action.payload?.forEach((element) => {
          if (element?.part?.partId) {
            state.parts[element?.part?.partId] = element?.part;
          }
        });
      }
    });

    builder.addCase(undoMaintenanceRequestLineItem.fulfilled, (state, action) => {
      if (action.payload?.part?.partId) {
        state.parts[action.payload?.part?.partId] = action.payload?.part;
      }
    });
  },
});

export const selectParts = (state) => state.parts.parts;

export const selectReorderParts = createSelector(
  [selectParts],
  (parts) => Object.keys(parts).reduce((current, p) => {
    const currentPart = parts[p];
    if (currentPart.reorderLevel >= 0
      && currentPart.reorderLevel >= Math.abs(currentPart.quantityOnHand) && currentPart.isActive) {
      return {
        ...current,
        [p]: {
          ...currentPart,
        },
      };
    }
    return current;
  }, {}),
);

export const selectReorderPartsCount = createSelector(
  [selectReorderParts],
  (
    reorderparts,
  ) => {
    return Object.values(reorderparts ?? {}).length;
  },
);

export const selectAllParts = createSelector(
  [selectParts],
  (parts) => Object.keys(parts).reduce((current, p) => {
    const currentPart = parts[p];
    return {
      ...current,
      [p]: {
        ...currentPart,
      },
    };
  }, {}),
);

export const selectLiquidParts = createSelector(
  [selectParts],
  (parts) => Object.keys(parts).reduce((current, p) => {
    const currentPart = parts[p];
    if (currentPart.assetTypeId === 2) {
      return {
        ...current,
        [p]: {
          ...currentPart,
        },
      };
    }
    return current;
  }, {}),
);

export const {
  setPartUpdateStatus,
  setCurrentPartId,
  setPartCreateStatus,
  setPartFetchSingleStatus,
  setPartFetchStatus,
  setRemoveAttachmentStatus,
  setCreateAttachmentStatus,
  setReactivatePartStatus,
} = partsSlice.actions;

export const selectGasParts = createSelector(
  [selectParts],
  (parts) => Object.keys(parts).reduce((current, p) => {
    const currentPart = parts[p];
    if (currentPart.assetTypeId === 1) {
      return {
        ...current,
        [p]: {
          ...currentPart,
        },
      };
    }
    return current;
  }, {}),
);

export const selectChassisParts = createSelector(
  [selectParts],
  (parts) => Object.keys(parts).reduce((current, p) => {
    const currentPart = parts[p];
    if (currentPart.assetTypeId === 4) {
      return {
        ...current,
        [p]: {
          ...currentPart,
        },
      };
    }
    return current;
  }, {}),
);
export const selectCurrentPartId = (state) => {
  return state.parts.currentPart.partId;
};

export const selectCurrentPart = createSelector([
  selectParts,
  selectCurrentPartId], (parts, partId) => {
  if (partId == null) {
    return {};
  }
  return parts[partId];
});

export const selectCreatePartStatus = (
  state,
) => state.parts.partCreate.status;

export const selectCreatePartError = (
  state,
) => state.parts.partCreate.error;

export const selectActiveLocationParts = createSelector(
  [
    selectParts,
    selectLoggedInUserActiveLocation,
    selectCurrentMaintenanceRequestTypeForTemplateEditData,
  ],
  (parts, location, requestType) => {
    const assetTypeId = requestType?.assetTypeId;
    if (assetTypeId && location?.LocationID) {
      return Object.keys(parts).reduce((current, p) => {
        const currentPart = parts[p];
        if (
          currentPart.assetTypeId === assetTypeId
           && currentPart.locationId === location?.LocationID
        ) {
          return {
            ...current,
            [p]: {
              ...currentPart,
            },
          };
        }
        return current;
      }, {});
    }
    return parts;
  },
);

export default partsSlice.reducer;
