import 'chartjs-adapter-date-fns';
import {
  CategoryScale,
  Chart as ChartJS,
  Decimation,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { formatDate } from '../../../../services/format';
import { selectLoggedInUserPreferences } from '../../../../store/slices/auth-slice';
import { FilterDefinition, FilterType } from '../list-view';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import debounce from 'lodash.debounce';
import { exportData } from '../../../../services/table-helpers';
import { getUniqueItemsByProperties } from '../../../../services/array-helpers';

const myColors = ['#005591', '#6c757d', '#00a0e1', '#002f5a', 'purple'];

export interface ChartFilter {
  name: any,
  number: any,
  label: any,
  defaultValue: any
}
export interface ChartSeries {
  name: any,
  type: any,
  x: any,
  y: any
}
export interface ChartProps {
  dataSource: any,
  dataSourceArgs: any,
  filters: FilterDefinition[],
  series: ChartSeries[],
  getValues?: any,
  entity?: any,
  metrics?: any[],
  enableExport?: boolean,
  customExport?: any
}

/* export interface ConnectedChartProps{
  
} */

export function ConnectedChart({field, entity}) {
  const { componentArgs, entityLoading } = field;
  const { series, dataSelector } = componentArgs;
  const data = useSelector(state => (dataSelector ? dataSelector(state) : entity) || []);

  const {
    datePreference,
  } = useSelector(selectLoggedInUserPreferences);

  React.useEffect(() => {
    let ds = series.map((s, idx) => {
      const datum = getUniqueItemsByProperties(data, [s.x]).map(d => ({ x: getTimestamp(d[s.x]), y: d[s.y] })).sort((a, b) => a.x - b.x)
      const label = s.name;
      const borderColor = myColors[idx];
      const radius = 0;
      const spanGaps = true;
      return {
        label,
        data: datum,
        borderColor,
        backgroundColor: borderColor,
        radius,
        spanGaps
      }
    });
    setDataSets(ds);
  }, [entity]);

  const [dataSets, setDataSets] = useState([]);

  const formatLabelDate = (dateString) => {
    const dt = new Date(dateString);
    const datePortion = formatDate(dt, datePreference);
    const hours = dt.getHours().toString().length === 1 ? `0${dt.getHours().toString()}` : dt.getHours().toString();
    const minutes = dt.getMinutes().toString().length === 1 ? `0${dt.getMinutes().toString()}` : dt.getMinutes().toString();
    return `${datePortion}`;
  };

  const getTimestamp = (dateString) => {
    const dt = new Date(dateString);
    return dt.getTime();
  };
 
  const options = {
    animation: false,
    parsing: false,
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false,
    },
    scales:
    {
      y: {
        ticks:
        {
          beginAtZero: true,
        },
      },
      x: {
        type: 'time',
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
          callback: (value) => {
            // eslint-disable-next-line key-spacing
            return formatLabelDate(value);
          },
        },
      },
    },
    tooltips: {
      mode: 'x-axis',
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
      },
      decimation: {
        enabled: true,
        algorithm: 'min-max',
        samples: 200,
      },
    },
  };

  ChartJS.register(
    CategoryScale,
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Decimation,
  );

  return (
    <>
      <div className="card-tools">
        {entityLoading && <Spinner
          animation="border"
          variant="primary"
          className={`create-spinner visible`}
        />}
        {!entityLoading && dataSets?.length && <Line
          // @ts-ignore
          options={options}
          data={{
            datasets: dataSets,
          }}
        />}
      </div>
    </>
  );
}
